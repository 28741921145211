:root {
    --intiza-red: #ef4e38;
    --intiza-blue: #0076b6;
    --intiza-darkBlue: #1f2b45;
    --intiza-black: #333333;
    --intiza-darkGrey: #8c8c8c;
    --intiza-grey3: #d7d7d7;
    --intiza-grey2: #d7deec;
    --intiza-white: #ffffff;
    --intiza-background: #f7f7f9;
    --intiza-status-red: #EF4E38;
    --intiza-status-orange: #FF7A00;
    --intiza-status-yellow: #998106;
    --intiza-status-green: #5AB43B;
    --intiza-status-darkGreen: #3A9D18;
    --intiza-alert-sm-warning-bg: #F8ECCE;
    --intiza-alert-sm-warning: #FF7A00;
    --intiza-alert-sm-success: #00670A;
    --intiza-alert-sm-success-bg: #DFFAE6;
    --intiza-alert-sm-danger-bg: #FFD1CB;
    --bs-body-font-size: 14px;
    --header-height: 95px;
}

/* --------------- RESET CSS --------------- */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

blockquote,
body,
dd,
div,
dl,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
input,
li,
ol,
p,
pre,
td,
textarea,
th,
ul {
    margin: 0;
    padding: 0;
}

ul,
ol {
    list-style: none;
}

a {
    color: inherit;
    text-decoration: inherit;
}

::selection {
    background-color: var(--intiza-blue);
    color: var(--intiza-white);
}

/* --------------- BUTTONS --------------- */

body {
    font-family: 'Lato', sans-serif;
    font-feature-settings: 'liga' off;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    background-color: var(--intiza-background);
    min-width: 1300px;
    overflow-x: auto;
}

.btn-primary,
.btn-secondary,
.btn-tertiary,
.btn-outline-light,
.btn-danger {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 5px;
    white-space: nowrap;
}

.btn-primary {
    background-color: var(--intiza-blue);
    color: var(--intiza-white);
    border: 1.5px solid var(--intiza-blue);
}

.bg-intiza,
.bg-intiza.is-active {
    background-color: var(--intiza-blue);
}

.btn-primary:hover {
    background-color: var(--intiza-darkBlue);
    border: 1.5px solid var(--intiza-darkBlue);
}

.btn-primary:active {
    background-color: var(--intiza-blue);
    color: var(--intiza-white);
}

:not(.btn-check)+.btn-primary:active {
    color: var(--intiza-white);
    background-color: var(--intiza-blue);
    border-color: var(--intiza-blue);
}

.btn-secondary {
    background-color: var(--intiza-white);
    color: var(--intiza-blue);
    border: 1.5px solid var(--intiza-blue);
}

.btn-secondary:hover {
    color: var(--intiza-darkBlue);
    border: 1.5px solid var(--intiza-darkBlue);
    background-color: var(--intiza-white);
}

.btn-secondary:active,
.btn-secondary:hover:active {
    color: var(--intiza-blue);
    border: 1.5px solid var(--intiza-blue);
    background-color: var(--intiza-gray2);
}

.btn-tertiary {
    background-color: var(--intiza-white);
    color: var(--intiza-black);
    border: 1.5px solid var(--intiza-darkGrey);
}

.btn-tertiary:hover {
    background-color: var(--intiza-white);
    color: var(--intiza-blue);
    border: 1.5px solid var(--intiza-blue);
}

.btn-tertiary:active {
    color: var(--intiza-blue);
    border: 1.5px solid var(--intiza-blue);
}

.btn-check:checked+.btn,
.btn-check.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn-check:active {
    color: var(--intiza-blue);
}

.btn-underline {
    background-color: transparent;
    color: var(--intiza-darkBlack);
    border: 1.5px solid transparent;
}

.btn-underline:hover {
    background-color: #EAF8FA;
    color: var(--intiza-blue);
    border: 1.5px solid #EAF8FA;
}


.btn-underline-danger {
    background-color: transparent;
    color: var(--intiza-red) !important;
    border: 1.5px solid transparent;
}

.btn-underline-danger:hover {
    background-color: var(--intiza-alert-sm-danger-bg);
    color: var(--intiza-red);
}

.btn-outline-light {
    background-color: var(--intiza-white);
    color: var(--intiza-black);
    font-weight: 600;
    border: 1px solid rgba(188, 188, 188, 0.5);
    padding: 7px 10px;
    gap: 10px;
}

.btn-outline-light:hover {
    background-color: var(--intiza-white);
    color: var(--intiza-black);
}

.btn-intiza {
    color: var(--intiza-blue);
    font-weight: 700;
    cursor: pointer;
}

.btnTrash {
    border: 0;
    background-color: unset;
}

.btnSort {
    border: 0;
    background-color: unset;
    color: var(--intiza-darkGrey);
}

/*--------------- FORMS ------------------------*/

label {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--intiza-darkGrey);
    margin-bottom: 3px;
}

.form-control {
    background: var(--intiza-white);
    border: 1px solid #C7C7C7;
    padding: 4px 13px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #000000;
}

.multiple-choice-select__control {
    border-radius: 4px;
    padding-top: 0;
    padding-bottom: 0;
    border-color: #C7C7C7;
}

.multiple-choice-select__value-container .multiple-choice-select__multi-value {
    background: rgba(0, 118, 182, 0.08);
    color: var(--intiza-blue);
}

.multiple-choice-select__multi-value .multiple-choice-select__multi-value__label {
    color: var(--intiza-blue);
}

.multiple-choice-select__control.multiple-choice-select__control--is-focused {
    border: 1px solid #C7C7C7;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.multiple-choice-select__control.multiple-choice-select__control--is-focused:hover {
    border: 1px solid #C7C7C7;
}

.multiple-choice-select__menu#react-select-3-listbox {
    padding: 0;
    line-height: 21px;
    color: var(--intiza-blue);
}

.multiple-choice-select__control.css-1s2u09g-control {
    width: 100%;
}

.multiple-choice-select__input-container input {
    line-height: 26px !important;
}

.form-check .form-check-label {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--intiza-black);
}

tr .form-check .form-check-label {
    font-size: 14px;
    padding-left: 12px;
}

.input-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0 8px;
}

.input-column {
    display: flex;
    flex-direction: column;
}

textarea {
    background: var(--intiza-white);
    border: 1px solid #C7C7C7;
    padding: 8px 13px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
}

.tabBody .tox.tox-tinymce {
    height: 230px;
}

.tabBody .tox:not(.tox-tinymce-inline) .tox-editor-header {
    box-shadow: none;
}

/* --------------- DROPDOWN INTIZA --------------- */

.dropdown-intiza .dropdown-menu:not(:has(.dropdown-submenu)) {
    padding: 0;
    background: var(--intiza-white);
    border: 1px solid #D3D3D3;
    box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    min-width: 100%;
    max-height: 500px;
}

.dropdown-intiza .dropdown-menu:not(:has(.dropdown-submenu)) {
    overflow-y: auto;
}

.autocomplete-menu .autocomplete-item {
    background: var(--intiza-white);
    min-width: 100%;
    max-height: 500px;
}

.autocomplete-menu .autocomplete-item a {
    padding: 12px 15px;
}

.autocomplete-menu .autocomplete-item a:focus,
.autocomplete-menu .autocomplete-item a:active,
.autocomplete-menu .autocomplete-item a:hover {
    background: #E3F5FF;
    color: var(--intiza-black);
}

.dropdown-intiza .dropdown-menu .dropdown-item,
.dropdown-intiza .dropdown-menu .dropdown-header {
    padding: 12px 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--intiza-black);
    border-bottom: 1px solid #E7E7E7;
}

.dropdown-intiza .dropdown-menu .dropdown-header {
    padding: 12px;
}

.dropdown-intiza .dropdown-menu .dropdown-item:hover {
    background: #E3F5FF;
}

.dropdown-intiza button.dropdown-toggle {
    color: #231F20;
    font-size: 24px;
    border: 0;
    background-color: transparent;
    line-height: 0;
    width: 100%;
}

.dropdown-intiza button.dropdown-toggle .fa-ellipsis-h {
    opacity: 0.7;
}

.dropdown-intiza button.dropdown-toggle::after {
    border: 0;
    content: unset;
}

.dropdown-submenu {
    position: relative;
    padding: 12px 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--intiza-black);
    border-bottom: 1px solid #E7E7E7;
}

.dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -1px;
}

.dropdown-submenu button {
    background-color: unset;
    color: var(--intiza-black);
    border: 0;
    outline: 0;
}

.dropdown-submenu:hover {
    background: #E3F5FF;
}

.dropdown-submenu .dropdown-menu {
    padding: 0;
    background: var(--intiza-white);
    border: 1px solid #D3D3D3;
    box-shadow: 0px 4px 19px rgb(0 0 0 / 5%);
    border-radius: 6px;
}

.dropdown-submenu .dropdown-menu button {
    background-color: unset;
    color: var(--intiza-black);
    border: 0;
    outline: 0;
    width: 100%;
    text-align: left;
    padding: 12px 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 1px solid #E7E7E7;
}

.dropdown-submenu .dropdown-menu button:hover {
    background: #E3F5FF;
}

div.drp-buttons>span.drp-selected {
    display: none;
}

.h-40px {
    height: 40px;
}

.w-40px {
    max-width: 40px;
}

.w-100px {
    max-width: 100px;
}

.w-120px {
    max-width: 120px;
}

.w-150px {
    max-width: 150px;
}

.w-200px {
    max-width: 200px;
}

.w-250px {
    max-width: 250px;
}

.w-325px {
    max-width: 325px;
}

.w-350px {
    max-width: 350px;
}

.w-400px {
    max-width: 400px;
}

/* --------------- SELECT INTIZA --------------- */

.dropdown-menu .select-intiza {
    padding: 0;
    background: var(--intiza-white);
    border: 1px solid #D3D3D3;
    box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
}

.btn.dropdown-toggle .select-intiza {
    background: var(--intiza-white);
    padding: 8px 13px;
    border: 1px solid #C7C7C7;
    border-radius: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #1E2A43;
    margin-left: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
}

.dropdown-menu .dropdown-item .select-intiza {
    padding: 12px 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--intiza-black);
    border-bottom: 1px solid #E7E7E7;
}

button.dropdown-toggle::after .select-intiza {
    border: 0;
    content: unset;
}

.dropdown-menu .dropdown-item:hover .select-intiza {
    background: #E3F5FF;
}


/* --------------- HEADER --------------- */

.navbar {
    padding: 0 40px;
    background: var(--intiza-white);
    border-bottom: 1px solid var(--intiza-grey2);
}

.navbar-brand {
    width: 111px;
}

header .navbar-nav {
    margin-left: 44px;
}

header .navbar-brand img {
    max-height: 64px;
}

header .navbar-nav .nav-item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 94px;
    justify-content: flex-end;
}

header .navbar-nav .nav-item .nav-link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 0;
    padding-top: 32px;
}

header .navbar-nav .nav-item .nav-link p {
    border: 0px solid transparent;
    border-right: 1px solid var(--intiza-grey2);
    padding: 5px 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #8B9195;
}

header .navbar-nav .nav-item hr {
    color: transparent;
    width: 100%;
    border: 2px solid;
    border-radius: 4px;
    opacity: 1;
    margin: 0;
}

header .navbar-nav .nav-item:hover .nav-link p,
header .navbar-nav .nav-item.active .nav-link p {
    color: var(--intiza-blue);
}

header .navbar-nav .nav-item:hover hr,
header .navbar-nav .nav-item.active hr {
    color: var(--intiza-blue);
    width: 70%;
    border: 2px solid;
    border-radius: 4px;
    margin: 0 auto;
    -webkit-animation: scale-up-hor-center 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-hor-center 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-hor-center {
    0% {
        -webkit-transform: scaleX(0.4);
        transform: scaleX(0.4);
    }

    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}

@keyframes scale-up-hor-center {
    0% {
        -webkit-transform: scaleX(0.4);
        transform: scaleX(0.4);
    }

    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}

header .navbar-nav .nav-item:last-child .nav-link p {
    border-right: 1px solid transparent;
}

header .dropdown-toggle::after {
    border-top: 0;
    content: unset;
}

header .navbar-expand-sm .navbar-nav .dropdown-menu.show {
    width: auto;
    left: -650px;
    top: 94px;
    background-color: var(--intiza-white);
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    padding: 48px 30px;
    gap: 0;
    grid-gap: 0;
    box-shadow: 0px 4px 65px rgba(31, 43, 69, 0.1);
    border-radius: 8px;
    border: 0;
}

header .navbar-nav .dropdown-menu ul {
    padding: 0 42px;
    width: 234px;
}

header .dropdown-menu ul:not(:last-child) {
    border-right: 1px solid #eeeeee;
}

header .dropdown-menu .dropdown-item {
    padding: unset;
}

header .nav-item.dropdown button.btn {
    border: unset;
}

header .dropdown-menu .dropdown-item {
    padding: 12px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #6F6F6F;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

header .dropdown-menu .dropdown-item:hover {
    color: var(--intiza-blue);
    background-color: transparent;
    font-weight: 600;
}

header .dropdown-menu h6.dropdown-item {
    color: #1f1f1f;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    margin: 0 0 7px 0;
}

header .dropdown-menu h6.dropdown-item:hover {
    background-color: transparent;
    color: #1f1f1f;
}

header .company-dropdown {
    padding: 0 21px;
    border-right: 1px solid var(--intiza-grey2);
}

header .userProfile-dropdown {
    padding: 0 0 0 21px;
}

header .company-dropdown .nav-link.dropdown-toggle,
header .userProfile-dropdown .nav-link.dropdown-toggle {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0 10px;
}

.userProfile-dropdown .dropdown-menu[data-bs-popper] {
    top: 56px;
    right: 0;
    left: -80px;
}

header .company-dropdown .dropdown-menu.show {
    width: auto;
    left: -150px;
    top: 56px;
    background-color: var(--intiza-white);
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    padding: 28px 0px;
    gap: 0;
    grid-gap: 0;
    box-shadow: 0px 4px 65px rgba(31, 43, 69, 0.1);
    border-radius: 8px;
    border: 0;
}

header .userProfile-dropdown .dropdown-menu.show {
    width: auto;
    background-color: var(--intiza-white);
    padding: 0;
    gap: 0;
    grid-gap: 0;
    box-shadow: 0px 4px 65px rgba(31, 43, 69, 0.1);
    border-radius: 8px;
    border: 1px solid #D3D3D3;
}

header .company-dropdown .nav-item.dropdown .nav-link p,
header .userProfile-dropdown .nav-item.dropdown .nav-link p,
header .company-dropdown .nav-item.dropdown-item,
header .userProfile-dropdown .nav-item.dropdown-item {
    font-weight: 600;
    font-size: 1rem;
    line-height: 21px;
    color: #595959;
}

header .companyDropdown__setup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 80px 32px 80px;
    border-bottom: 1px solid #eeeeee;
}

header .companyDropdown__setup-img {
    width: 76px;
    height: 76px;
}

header .companyDropdown__setup-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 0 0 16px 0;
}

header .companyDropdown__setup-name {
    font-weight: 600;
    font-size: 21px;
    line-height: 21px;
    text-align: center;
    color: var(--intiza-darkBlue);
}

header .companyDropdown__setup-users {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: var(--intiza-blue)
}

header .notifications__setup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 24px;
    width: 400px;
    border-bottom: 1px solid #eeeeee;
}

header .notifications__setup-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

header button .fa-cog {
    color: var(--intiza-blue);
    margin: 2px 0 0 0;
}

header .changeCompany {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0 10px;
    padding: 13px 32px;
    border-bottom: 1px solid #eeeeee;
}

header .changeCompany__name h5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: var(--intiza-black);
}

header .changeCompany__name p {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: var(--intiza-darkGrey);
}

header .addCompany {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0 10px;
    padding: 26px 32px 0 32px;
}

header .addCompany h5 {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: var(--intiza-blue);
}

header .userProfile-dropdown-account {
    border-bottom: 1px solid #E7E7E7;
    padding: 12px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

header .userProfile-dropdown-account p,
header .userProfile-dropdown-exit p {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--intiza-black);
}


header .userProfile-dropdown-exit {
    padding: 12px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

header .userProfile-dropdown-account i,
header .userProfile-dropdown-exit i {
    color: var(--intiza-blue);
}

@media (max-width: 1600px) {
    header .navbar-expand-sm .navbar-nav .dropdown-menu.show {
        left: -700px;
    }

    header .navbar-nav .dropdown-menu ul {
        padding: 0 30px;
        width: 200px;
    }
}

@media (max-width: 1400px) {
    header .navbar-nav {
        margin-left: 0px;
    }

    header .navbar-expand-sm .navbar-nav .dropdown-menu {
        left: -550px;
    }
}

@media (max-width: 576px) {

    .navbar-nav,
    .navbar-collapse {
        display: flex;
        flex-flow: row nowrap;
    }

    header .dropdown-menu.show {
        position: absolute;
    }
}

.nav-pills.client-detail {
    padding: 4px;
    margin: 24px 0 0 0;
    border-radius: 6px;
    gap: 0;
    background: var(--intiza-white);
    border: 1px solid var(--intiza-grey2);
    width: fit-content;
}

.nav-pills.client-detail .nav-item {
    padding: 0;
}

.nav-pills.client-detail .nav-item .nav-link {
    font-size: 16px;
    font-weight: 500;
    background: transparent;
    padding: 8px 32px;
    color: var(--intiza-blue);
    border: 1px solid transparent;
}

.nav-pills.client-detail .nav-item .nav-link.active {
    background: var(--intiza-blue);
    color: var(--intiza-white);
    border: 1px solid transparent;
    border-radius: 4px;
    transition: all linear .25s;
}


/* --------------- FOOTER --------------- */

footer {
    background-color: var(--intiza-darkBlue);
    display: flex;
    align-items: center;
    width: 100%;
    padding: 42px 40px;
}

.footerTop {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    color: var(--intiza-white);
    margin-bottom: 45px;
}

.footerTop__logo {
    width: 94px;
    height: auto;
}

.footerTop__menu {
    display: flex;
    flex-flow: row nowrap;
    gap: 0;
    align-items: center;
}

.footerTop__menu a {
    padding: 0px 18px;
    border-right: 1px solid var(--intiza-white);
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.204545px;
    color: var(--intiza-white);
}

.footerTop__menu a:last-child {
    border-right: 0;
}

.footerTop__media {
    display: flex;
    flex-flow: row nowrap;
    gap: 0 30px;
}

.footerBottom {
    display: flex;
    width: 100%;
    justify-content: center;
}

.footerBottom p {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #F5F5F5;
    mix-blend-mode: normal;
    opacity: 0.7;
}

/* --------------- BOOTSTRAP --------------- */

.text-danger {
    color: var(--intiza-red);
}

.text-intiza {
    color: var(--intiza-blue);
}

.text-white {
    color: var(--intiza-white) !important;
    ;
}

.card {
    background: var(--intiza-white);
    border: 1px solid var(--intiza-grey2);
    border-radius: 6px;
    padding: 30px;
}

.card-body {
    padding: 0;
}

.card-header {
    background-color: var(--intiza-white);
    border-bottom: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin-bottom: 12px;
}

.table-responsive {
    overflow-y: auto;
}

.form-check-input,
.form-check-input:focus {
    box-shadow: unset;
    border: 1px solid #8B9195;
}

.form-check-input:checked {
    background-color: var(--intiza-blue);
    border: 1px solid var(--intiza-blue);
    box-shadow: unset;
}

.alert-info {
    --bs-alert-bg: rgba(0, 118, 182, 0.1);
    --bs-alert-border-color: rgba(0, 118, 182, 0.1);
}

.form-switch {
    display: flex;
    gap: 8px;
}

.form-switch .form-check-input {
    width: 40px;
    height: 20px;
}

.fs-7 {
    font-size: 14px;
}

.textPrimary {
    color: var(--intiza-blue);
}

hr {
    border-color: #EAEAEA;
    opacity: 1;
}

.alert {
    border: 0;
    font-size: 16px;
    padding: 22px 24px;
}

.alert.alert-primary {
    background-color: #EBF4FA;
    color: var(--intiza-blue);
}

.alert.alert-success {
    background-color: #DFFAE6;
    color: #00670A;
}

.z-index-10 {
    z-index: 10;
}

.text-nowrap {
    text-wrap: nowrap;
}

.overflow-y-auto {
    overflow-y: auto;
}

/* --------------- DASHBOARD --------------- */

.dashboard {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
}

.dashboard .container-fluid {
    padding: 16px 40px 60px 40px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.card-header .menu {
    display: flex;
    white-space: nowrap;
    flex-direction: row;
    align-items: center;
    gap: 0 9px;
}

.card-header select {
    margin: 0 0 0 18px;
    width: 160px;
}

.card-header .view-all {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: var(--intiza-blue);
}

.card-header .view-all:hover a {
    color: var(--intiza-blue);
}

.card-header .view-all:hover span {
    box-shadow: 0px 2px 0px var(--intiza-blue);
    color: var(--intiza-blue);
}

.card-header .btn:hover i {
    background-color: unset;
    border: 0;
}

.dashboard__total .card-header p,
.dashboard__expired .card-header p,
.dashboard__notExpired .card-header p {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #777676;
}

.dashboard__sales h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.5;
}

.dashboard__sales .dashboard__total h2 {
    color: var(--intiza-black);
}

.dashboard__sales .dashboard__expired h2 {
    color: var(--intiza-red);
}

.dashboard__sales .dashboard__notExpired h2 {
    color: var(--intiza-blue);
}

.dashboard__expired span {
    padding: 5px 14px;
    background: #FFEDED;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    color: var(--intiza-status-red);

}

.dashboard__notExpired span {
    padding: 5px 14px;
    background: #DFF4FF;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    color: var(--intiza-blue);
}

.dashboard__total span {
    padding: 5px 14px;
    background: var(--intiza-white);
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    color: var(--intiza-white);
    visibility: hidden;
    min-height: 34px;
}


.dashboard__graphics .row .col-3 {
    border-right: 1px solid #d7deec44;
}

.dashboard__graphics .row h5 {
    font-weight: 600;
    font-size: 21px;
    line-height: 21px;
    color: var(--intiza-black);
}

.dashboard .card .card-header h2 {
    font-weight: 600;
    font-size: 21px;
    line-height: 21px;
    color: var(--intiza-black);
}

.dashboard .card .card-header .btn-secondary {
    border: 0;
    padding: 4px 0 0 0;
    font-size: 20px;
    color: var(--intiza-blue);
}

.dashboard .card .card-header .btn:active {
    background-color: transparent;
}

@media (max-width: 1600px) {

    .dashboard__sales .dashboard__total h2,
    .dashboard__sales .dashboard__expired h2,
    .dashboard__sales .dashboard__notExpired h2 {
        font-size: 32px;
    }
}

@media (max-width: 1400px) {

    .dashboard__sales .dashboard__total h2,
    .dashboard__sales .dashboard__expired h2,
    .dashboard__sales .dashboard__notExpired h2 {
        font-size: 24px;
    }
}

.table thead th {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--intiza-darkGrey);
    background-color: var(--intiza-white);
    min-width: 100px;
}

.table tr:hover {
    background-color: #F2F9FD;
}

.table tr.no-hover:hover {
    background-color: unset;
}

.table thead tr th:first-child,
.table tbody tr td:first-child,
.table tfoot tr td:first-child {
    padding-left: 16px;
}

.table thead tr th:last-child,
.table tbody tr td:last-child,
.table tfoot tr td:last-child {
    padding-right: 16px;
}


.table>:not(caption)>*>* {
    border-bottom: 1px solid #D7DEEC;
    padding: 10px 0.5rem;
}

.table tbody tr:last-child td {
    border-bottom: 0;
}

/* ------------- TABLE PRIORITY ------------ */

.tablePriority span {
    border-radius: 1.98496px;
    font-weight: 600;
    font-size: 11.9097px;
    line-height: 14px;
    padding: 4px 7px;
    margin-right: 7px;
}

.tablePriority .priority-1 {
    background: #FFC9C1;
    color: var(--intiza-status-red);
}

.tablePriority .priority-2 {
    background: #FFDBB9;
    color: var(--intiza-status-orange);
}

.tablePriority .priority-3 {
    background: #FAF3B3;
    color: var(--intiza-status-yellow);
}

.tablePriority .priority-4 {
    background: #EEF7EC;
    color: var(--intiza-status-green);
}

.tablePriority .priority-5 {
    background: #d2ead2;
    color: var(--intiza-status-darkGreen);
}

.tablePriority .priority-6 {
    background: #d2ead2;
    color: var(--intiza-status-darkGreen);
}

.tablePriority td {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: var(--intiza-black);
}

.tablePriority .bar-progress {
    vertical-align: middle;
    width: 220px;
}

.tablePriority .bar-progress .bar {
    background-color: #DCE5EA;
    width: 100%;
    height: 10px;
    overflow: hidden;
    border-radius: 0px;
    display: flex;
    flex-direction: row;
}

.tablePriority .bar-progress .bar .expired {
    background-color: var(--intiza-red);
    width: 40%;
    height: 10px;
}

.tablePriority .bar-progress .bar .not-expired {
    background-color: var(--intiza-blue);
    width: 30%;
    height: 10px;
}

.tablePriority tfoot tr td {
    border: 0;
}

.table tfoot tr:last-child td {
    border-top: 1px solid #D7DEEC;
}

.table tfoot tr:hover {
    background-color: var(--intiza-white);
}


/* ------------- TABLE PENDING TASKS ------------ */

.pendingTasksRow tbody tr {
    font-size: 16px;
    color: var(--intiza-black);
    font-weight: 400;
    line-height: 24px;
}


/* ------------- TABLE TASKS EXPIRED ------------ */

.pendingTasksRow tbody tr {
    font-size: 16px;
    color: var(--intiza-black);
    font-weight: 400;
    line-height: 24px;
}

/* ------------- CLIENTS ------------ */

.container-fluid.padding {
    padding: 16px 40px 40px 40px;
}

@media (width < 1400px) {
    .container-fluid.padding {
        padding: 16px 40px 16px 40px;
    }
}

/* ------------- GENERIC HEADER ------------ */

.genericHeader {
    display: flex;
    flex-flow: row nowrap;
    gap: 24px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 0 20px 0;
}

.searcherFilterHeader {
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.search-input.input-group {
    max-width: 360px;
    height: 100%;
}

.search-input.input-group .btn-outline-secondary {
    border: 1px solid #bcbcbc;
    color: #bcbcbc;
    position: absolute;
    z-index: 6;
    border: 0;
    left: 0;
    height: 100%;
    border-radius: 0;
    border-right: 0;
}

.search-input.input-group input {
    position: relative;
    bottom: 0;
    z-index: 0;
    border-radius: 6px !important;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    color: #bcbcbc;
}

.search-input.input-group input:focus {
    color: var(--intiza-blue);
    box-shadow: unset;
    border: 1px solid var(--intiza-blue);
}

.search-input.input-group:hover input {
    border-color: var(--intiza-blue);
    box-shadow: unset;
}

.search-input.input-group:hover button {
    color: var(--intiza-blue);
    background-color: transparent;
}

.search-input.input-group input::placeholder {
    color: #bcbcbc;
}

.search-input.input-group:hover input::placeholder {
    color: var(--intiza-blue);
}

.searcherFilterHeader .btn.btn-tertiary {
    border: 1px solid #bcbcbc;
    color: #bcbcbc;
}

.searcherFilterHeader .btn.btn-tertiary:hover {
    border: 1px solid var(--intiza-blue);
    color: var(--intiza-blue);
}

.filterRow-bg {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.filterRow-bg .row {
    width: 100%;
    padding: 0;
}

.filterRow-bg .row .btn.btn.dropdown-toggle {
    width: 100%;
}

.filterRow-bg .row .btn.dropdown-toggle .select-intiza {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.filterRow-bg .row .btn.dropdown-toggle .select-intiza:hover {
    border: 1px solid var(--intiza-blue);
    color: var(--intiza-blue);
}

.delete .btn {
    color: var(--intiza-red);
    font-size: 20px;
    padding-right: 0;
}

.delete .btn:active {
    border: 1px solid transparent;
}

.filter-body .filtersActions {
    display: flex;
    flex-grow: row nowrap;
    justify-content: space-between;
    margin: 24px 28px 0 0;
}

.btn-link {
    text-decoration: none;
    color: var(--intiza-blue);
    font-weight: 600;
}

.btn-link-dashboard {
    text-decoration: none;
    color: var(--intiza-blue);
    font-weight: 400;
    font-size: 11px;
    padding: 2px;
}

.filtersActions .actions {
    display: flex;
    flex-flow: row nowrap;
    gap: 24px;
}

.actionButtonsHeader {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 24px;
}

.actionButtonsHeader .btn.dropdown-toggle .select-intiza {
    background-color: var(--intiza-blue);
    color: var(--intiza-white);
    padding: 11px 13px;
    border: 1px solid var(--intiza-blue);
}

.btn.dropdown-toggle .select-intiza.select-export {
    background: var(--intiza-white);
    padding: 8px 13px;
    border: 1px solid #C7C7C7;
    border-radius: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #1E2A43;
    margin-left: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.actionButtonsHeader .btn.dropdown-toggle:hover .select-intiza:not(.select-export) {
    background-color: var(--intiza-darkBlue);
    color: var(--intiza-white);
    border: 1px solid var(--intiza-darkBlue);
}

.actionButtonsHeader .btn.btn-underline {
    padding: 11px 16px;
    display: flex;
    align-items: center;
}

.actionButtonsHeader .btn:not(.btn-primary) {
    padding: 0;
}

.actionButtonsHeader .btn.btn-underline i {
    font-size: 16px;
}

/*  ------------ GENERIC TABLE ----------*/

.tableContainer.sticky-top {
    position: sticky;
    z-index: 1;
    top: 0;
}

.table.genericTable {
    border-collapse: collapse;
    min-width: 992px;
    overflow-x: auto;
}

.table.genericTable,
.table.genericTable tbody {
    overflow-y: auto;
    height: 100%;
}

.table.genericTable tbody tr {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}

.table.genericTable tbody tr td {
    white-space: nowrap;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: fit-content;
}

.table.genericTable tbody tr td.w-80px {
    max-width: 80px;
}

.table.genericTable tbody tr td.w-40px {
    min-width: 40px;
    width: 40px;
}

.table.genericTable tbody tr td.w-150px {
    width: 150px;
    min-width: 150px;
}

.table.genericTable tbody tr td.w-200px {
    width: 200px;
    min-width: 200px;
}

.table.genericTable tbody tr td.w-200px {
    width: 200px;
    min-width: 200px;
}

.table.genericTable tbody tr td.w-400px {
    width: 400px;
    min-width: 400px;
}


.table.genericTable tr th.long-text {
    min-width: 250px;
    width: 250px;
}

.table.genericTable tbody tr td.text-end {
    max-width: unset;
}

.table.genericTable tbody tr td.w-unset {
    max-width: unset;
}

.table.genericTable thead tr {
    background-color: var(--intiza-white);
}

.table.genericTable thead tr th {
    white-space: nowrap;
    cursor: pointer;
    background-color: transparent;
    min-width: 100px;
}

.table.genericTable thead tr th div {
    display: inline-block;
}

.table.genericTable thead tr th div.text-end {
    width: 100%;
}

.table.genericTable thead tr th div.text-end:has(+ i) {
    width: calc(100% - 18px);
}

.table.genericTable thead tr th.w-30px {
    max-width: 30px;
    min-width: 30px;
    width: 30px;
}

.table.genericTable thead tr th.w-40px {
    max-width: 40px;
    min-width: 40px;
    width: 40px;
}

.table.genericTable thead tr th.w-80px {
    min-width: 80px;
}

.table.genericTable thead tr th.w-80px-fixed {
    max-width: 80px;
    min-width: 80px;
    width: 80px;
}

.table.genericTable thead tr th.w-100px {
    min-width: 100px;
}

.table.genericTable thead tr th.w-120px {
    min-width: 120px;
}

.table.genericTable thead tr th.w-150px {
    min-width: 150px;
}

.table.genericTable thead tr th.w-180px {
    min-width: 180px;
}

.table.genericTable thead tr th.w-200px {
    min-width: 200px;
}

.table.genericTable thead tr th.w-250px {
    min-width: 250px;
}

.table.genericTable thead tr th.w-300px {
    min-width: 300px;
}

.table.genericTable thead tr th.w-325px {
    min-width: 325px;
}

.table.genericTable thead tr th.w-400px {
    min-width: 400px;
}

.table.genericTable thead tr th.w-350px {
    min-width: 350px;
}


.tableContainer.sticky-top .table thead tr th {
    position: sticky;
    top: 0;
    z-index: 1;
}

.tableContainer.sticky-top .table thead tr th:hover {
    max-width: unset;
    width: auto;
}

.tableContainer.sticky-top .table thead tr th:hover div.ellipsis-oneline {
    max-width: unset;
}

.tableContainer.sticky-top .table thead tr th div.ellipsis-oneline+i {
    max-width: 100%;
    position: relative;
    bottom: 4px;
}

.mw-100 {
    min-width: 100px;
}

.mw-200 {
    min-width: 200px;
}

.mw-250 {
    min-width: 250px;
}

.mw-350 {
    min-width: 350px;
}

.genericTable .bar-progress {
    vertical-align: middle;
    width: 220px;
}

.genericTable .bar-progress .bar {
    background-color: #DCE5EA;
    width: 100%;
    height: 10px;
    overflow: hidden;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
}

.genericTable .bar-progress .bar .expired {
    background-color: var(--intiza-red);
    width: 40%;
    height: 10px;
}

.genericTable .bar-progress .bar .not-expired {
    background-color: var(--intiza-blue);
    width: 30%;
    height: 10px;
}

.genericTable tr td .priority-1,
.genericTable tr td .priority-2,
.genericTable tr td .priority-3,
.genericTable tr td .priority-4,
.genericTable tr td .priority-5,
.genericTable tr td .priority-6 {
    border-radius: 1.98496px;
    font-weight: 600;
    font-size: 11.9097px;
    line-height: 14px;
    padding: 4px 7px;
    margin-right: 7px;
}


.genericTable .priority-1 {
    background: #FFC9C1;
    color: var(--intiza-status-red);
}

.genericTable .priority-2 {
    background: #FFDBB9;
    color: var(--intiza-status-orange);
}

.genericTable .priority-3 {
    background: #FAF3B3;
    color: var(--intiza-status-yellow);
}

.genericTable .priority-4 {
    background: #EEF7EC;
    color: var(--intiza-status-green);
}

.genericTable .priority-5 {
    background: #d2ead2;
    color: var(--intiza-status-darkGreen);
}

.genericTable .priority-6 {
    background: #d2ead2;
    color: var(--intiza-status-darkGreen);
}

/*  ------------ PAGINATION ----------*/


nav.navigation {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
}

nav .pagination {
    --bs-pagination-color: var(--intiza-black);
    --bs-pagination-border-width: 0;
    --bs-pagination-hover-color: var(--intiza-black);
    --bs-pagination-hover-bg: transparent;
    --bs-pagination-hover-border-color: none;
    --bs-pagination-focus-color: unset;
    --bs-pagination-focus-bg: unset;
    --bs-pagination-focus-box-shadow: unset;
    --bs-pagination-disabled-color: var(--intiza-grey3);
    justify-content: center;
    align-items: center;
    gap: 5px;
}

nav .pagination .page-item.active .page-link {
    color: var(--intiza-blue);
    background-color: #EBF8FF;
}

nav .pagination .page-link {
    border-radius: 10px;
    padding: 5px 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    cursor: pointer;
    max-width: 37px;
}

nav.navigation .results {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    white-space: nowrap;
    justify-content: flex-end;
}

nav.navigation .results p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #595959;
}

/*  ------------ ERROR PAGE ------------ */

.errorBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
}

.errorBody img {
    width: 200px;
    margin-bottom: 48px;
}

.errorBody h2 {
    font-weight: 900;
    font-size: 32px;
    color: var(--intiza-darkBlue);
    margin-bottom: 8px;
    max-width: 600px;
}

.errorBody p {
    font-weight: 400;
    font-size: 24px;
    color: var(--intiza-darkGrey);
    margin-bottom: 64px;
    max-width: 600px;
}

.errorBody .btn-primary {
    font-size: 20px;
    line-height: auto;
}

/*  ------------ TOOLTIP INTIZA ------------ */

.information-icon {
    font-size: 20px;
    color: var(--intiza-blue);
}

.tooltip.tooltipIntiza {
    --bs-tooltip-opacity: 0.99;
    border-radius: 8px;
    filter: drop-shadow(0px 2px 15px rgba(0, 0, 0, 0.30));
}

.tooltip.tooltipIntiza .tooltip-inner {
    background-color: var(--intiza-white);
    color: #000000;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    padding: 10px 14px;
    opacity: 1;
    max-width: 380px;
    text-align: left;
}

.tooltip.tooltipIntiza.bigTooltip .tooltip-inner {
    max-width: 70vw;
}


.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
    border-top-color: var(--intiza-white);
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
    border-right-color: var(--intiza-white);
}

.table-loader-container {
    position: relative;
    margin-bottom: 24px;
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
    border-left-color: var(--intiza-white);
}

.table-loader-loading-fade.background-fade {
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 12;
    -webkit-transition: background-color 0.6s ease;
    -o-transition: background-color 0.6s ease;
    transition: background-color 0.6s ease;
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
    border-bottom-color: var(--intiza-white);
}

.tooltipPosition {
    width: fit-content;
    display: inline-block;
}

.tooltipClients h5 {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: var(--intiza-blue);
    margin-bottom: 13px;
}

.tooltipClients .tooltipData {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
}

.tooltipClients .tooltipData h6 {
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--intiza-darkGrey);
    border-bottom: 1px solid var(--intiza-grey2);
    padding: 8px 8px 8px 0;
}

.tooltipClients .tooltipData:last-child h6 {
    border-bottom: 1px solid transparent;
}

.tooltipClients .tooltipData p {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--intiza-black);
    border-bottom: 1px solid transparent;
    padding: 8px 0;
}

.tooltipClients .tooltipData a {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--intiza-blue);
    border-bottom: 1px solid transparent;
    padding: 8px 0;
}

.tooltipLastManagement {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: var(--intiza-darkGrey);
}

.tooltipLastManagement h6 {
    font-weight: 600;
}

.tooltipLastManagement p {
    color: var(--intiza-black);
}

.table-loader-loading-fade i {
    color: #77b0dc00;
}

.table-loader-loading-fade.background-fade i {
    -webkit-transition: color 0.6s ease;
    -o-transition: color 0.6s ease;
    transition: color 0.6s ease;
    color: #77b0dc;
}

/*  ------------ GENERIC MODAL ------------ */

.genericModal {
    border-radius: 8px;
    border: 0;
}

.genericModal .modal-header {
    background-color: var(--intiza-darkBlue);
    color: #fff;
    padding: 13px 15px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
}

.genericModal .modal-header .btn-close {
    color: #fff;
    background: url(../../assets/img/close.svg) no-repeat center;
    opacity: 1;
}

.genericModal .modal-header .btn-close:focus {
    box-shadow: unset;
}

.genericModal .modal-dialog .select-intiza span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
}

.genericModal .modal-body {
    background: #F8F8F8;
    padding: 25px 48px 25px 48px;
    font-size: 16px;
}

.genericModal .modal-footer {
    background: #F8F8F8;
    border-top: 0;
    padding: 25px 48px 25px 48px;
    gap: 0.25rem;
}

.genericModal .modal-footer .btn-underline {
    border: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    padding: 10px 38px;
    color: var(--intiza-black);
}

.genericModal .modal-footer .btn-primary {
    padding: 10px 38px;
    margin: 0;
    border: 0;
}

.genericModal label {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--intiza-darkGrey);
    margin-bottom: 3px;
    white-space: nowrap;
}

.genericModal input.form-control {
    background: var(--intiza-white);
    border: 1px solid #C7C7C7;
    padding: 8px 13px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
}


/*  ------------ CHIPS ------------ */

.chipsRowFilter {
    display: flex;
    flex-flow: row wrap;
    gap: 8px;
    align-items: center;
    margin-bottom: 20px;
}

.d-flex.align-items-center>*>.chipsRowFilter {
    margin-bottom: 0px;
}

.chipItem {
    background: rgba(0, 118, 182, 0.08);
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--intiza-blue);
    padding: 4px 10px;
    border: 0;
}

.btn.chipItem:hover {
    background: rgba(0, 118, 182, 0.08);
    color: var(--intiza-blue);
}

.btn.chipItem:focus {
    border: 0;
}

.chipItemDisabled {
    background: #F8F8F8;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #858585;
    padding: 4px 10px;
    border: 0;
}

.btn.chipItemDisabled:hover {
    background: #F8F8F8;
    color: #858585;
}

.btn.chipItemDisabled:focus {
    border: 0;
}

/*  ------------ DEBTS ------------ */

.app-height {
    height: calc(100vh - var(--header-height));
}

.debts .debtsMenu {
    background: var(--intiza-white);
    display: flex;
    flex-direction: column;
    padding: 0;
    height: auto;
    position: relative;
    border-right: 1px solid var(--intiza-grey2);
}

.debts .debtsMenu .resumeCompany {
    padding: 21px 24px 24px 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-grow: 0;
    flex-shrink: 0;
}

.debts .resumeCompany .btn-primary:active {
    color: var(--intiza-white);
}

.debts .nameCompany i {
    display: none;
}

.debts .nameCompany:hover i {
    display: inline-block;
    font-size: 16px;
    margin-left: 12px;
}

.debts .nameCompany h2 {
    font-size: 30px;
    font-weight: 700;
    line-height: 30px;
    color: var(--intiza-darkBlue);
}

.debts .nameCompany.canEdit h2:hover {
    cursor: pointer;
}

.debts .viewData label {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--intiza-darkGrey);
}

.debts .debtsMenu .boxes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.debts .spacing-boxes {
    border-bottom: 1px solid #d7d7d786;
    padding-bottom: 24px;
    margin-bottom: 24px;
}

.debts .debtsMenu .boxes .box {
    padding: 8px 0;
    background: var(--intiza-white);
    border-radius: 6px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.debts .debtsMenu .boxes .box h5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--intiza-darkGrey);
}

.debts .debtsMenu .boxes .box p {
    font-weight: 600;
    font-size: 14px;
    color: var(--intiza-darkBlue);
}

.debts .debtsMenu .actions {
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    gap: 24px;
}

.debts .debtsMenu .actions .actionButton {
    padding: unset;
    background: unset;
    border: unset;
    margin: unset;
    background-color: #EAF8FA;
    height: 32px;
    width: 32px;
}

.debts .debtsMenu .actions .actionButton i {
    color: var(--intiza-blue);
}

.debts .debtsMenu .aboutCompany {
    border-top: 1px solid #d7d7d786;
    padding: 24px 45px 78px 24px;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
}

.debts .debtsMenu .aboutCompany h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: var(--intiza-darkBlue);
    margin-bottom: 4px;
}

.debts .debtsMenu .aboutCompany .aboutCompanyItem {
    display: flex;
    gap: 5px;
    padding: 10px 0;
}

.debts .debtsMenu .aboutCompany .aboutCompanyItem:last-child {
    border-bottom: 0;
}

.debts .debtsMenu .aboutCompany .aboutCompanyItem h5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--intiza-darkGrey);
}

.debts .debtsMenu .aboutCompany .aboutCompanyItem p,
.debts .debtsMenu .aboutCompany .aboutCompanyItem label {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--intiza-black);
    white-space: normal;
    word-break: break-all;
}

.debts .debtsMenu .aboutCompany .aboutCompanyItem span.priority {
    border-radius: 1.98496px;
    font-weight: 600;
    font-size: 11.9097px;
    line-height: 14px;
    padding: 4px 7px;
    margin-right: 7px;
    width: fit-content;
}

.debts .debtsMenu .aboutCompany .aboutCompanyItem a {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-decoration: underline;
    color: var(--intiza-blue);
    word-break: break-all;
    white-space: normal;
}

.priority.priority-1,
.priority.priority-2,
.priority.priority-3,
.priority.priority-4,
.priority.priority-5 {
    border-radius: 2px;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    padding: 4px 7px;
    margin-right: 7px;
}


.priority.priority-1,
.debts .debtsMenu .aboutCompany .aboutCompanyItem .priority-1 {
    background: #FFC9C1;
    color: var(--intiza-status-red);
}

.priority.priority-2,
.debts .debtsMenu .aboutCompany .aboutCompanyItem .priority-2 {
    background: #FFDBB9;
    color: var(--intiza-status-orange);
}

.priority.priority-3,
.debts .debtsMenu .aboutCompany .aboutCompanyItem .priority-3 {
    background: #FAF3B3;
    color: var(--intiza-status-yellow);
}

.priority.priority-4,
.debts .debtsMenu .aboutCompany .aboutCompanyItem .priority-4 {
    background: #EEF7EC;
    color: var(--intiza-status-green);
}

.priority.priority-5,
.debts .debtsMenu .aboutCompany .aboutCompanyItem .priority-5 {
    background: #d2ead2;
    color: var(--intiza-status-darkGreen);
}

.debts .debtsMenu .aboutCompany .aboutCompanyItem .priority-6 {
    background: #d2ead2;
    color: var(--intiza-status-darkGreen);
}

.debts .debtsDetail {
    padding: 0 40px 24px 24px;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
}

.debtsDetail .breadcrumb {
    margin-bottom: 0;
}

.sticky-clientname {
    position: sticky;
    top: 0;
    background-color: #f7f7f9;
    z-index: 9;
    padding-top: 24px;
    border-bottom: 1px solid #d7d7d786;
}

.breadcrumb {
    margin-bottom: 8px;
}

.breadcrumb-divider {
    --bs-breadcrumb-divider: '\f054';
}

.breadcrumb-controls i,
.breadcrumb-item+.breadcrumb-item::before {
    font-weight: 400;
    font-family: var(--fa-style-family, "Font Awesome 6 Pro");
    font-size: .75em;
    line-height: 2.30333em;
}

.breadcrumb-controls input {
    width: 200px;
}

.breadcrumb-item,
.breadcrumb-item.active span,
.breadcrumb-controls {
    font-weight: 500;
    font-size: 15px;
    line-height: 26px;
    color: var(--intiza-darkGrey);
}

.breadcrumb-item:hover {
    text-decoration: none;
}

.css-1s2u09g-control {
    height: 24px;
}

.multiple-choice-select__indicators {
    height: 36px;
}

.multiple-choice-select__value-container.css-319lph-ValueContainer {
    padding: 0 8px;
}

.multiple-choice-select__indicators .css-tlfecz-indicatorContainer {
    height: 36px;
    align-items: center;
    padding-top: 10px;
}

.breadcrumb-item.active:hover {
    text-decoration: none;
}

.debts .creditLimit .creditLimitBox {
    background: var(--intiza-white);
    border: 1px solid var(--intiza-grey2);
    border-radius: 6px;
    padding: 24px 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.debts .creditLimit .creditLimitBox h5 {
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    color: var(--intiza-darkGrey);
}

.debts .creditLimit .creditLimitBox p {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: var(--intiza-black);
}

.nav-pills {
    padding: 24px 48px 0 48px;
    border-bottom: 2px solid var(--intiza-grey2);
    display: flex;
    flex-flow: row nowrap;
    gap: 0 40px;
}

.nav-pills .nav-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 10px;
}

.nav-pills .nav-item .nav-link {
    background-color: var(--intiza-white);
    color: var(--intiza-blue);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
}

.nav-pills .nav-item button p {
    padding: 0 10px 12px 10px;
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    white-space: nowrap;
}

.nav-pills .nav-item button p.text-truncate {
    max-width: 100px;
}

.nav-pills .nav-item button .rounded,
.nav-pills .nav-item .rounded {
    background-color: transparent;
    height: 5px;
    width: 100%;
    position: relative;
    top: 1px;
}

.nav-pills .nav-item button.active .rounded,
.nav-pills .nav-item.active .rounded {
    background-color: var(--intiza-blue);
}

.nav-pills.client-detail {
    padding: 4px;
    margin: 24px 0 0 0;
    border-radius: 6px;
    gap: 0;
    background: var(--intiza-white);
    border: 1px solid var(--intiza-grey2);
    width: fit-content;
}

.nav-pills.client-detail .nav-item {
    padding: 0;
}

.nav-pills.client-detail .nav-item .nav-link {
    font-size: 16px;
    font-weight: 500;
    background: transparent;
    padding: 8px 32px;
    color: var(--intiza-blue);
    border: 1px solid transparent;
}

/* 
.nav-pills.client-detail .nav-item .nav-link:hover {
    color: var(--intiza-blue);
    background: var(--intiza-white);
    border: 1px solid var(--intiza-blue);
} */

.nav-pills.client-detail .nav-item .nav-link.active {
    background: var(--intiza-blue);
    color: var(--intiza-white);
    border: 1px solid transparent;
    border-radius: 4px;
    transition: all linear .25s;
}

.tab-content {
    padding: 32px 24px;
}

.tab-content .dropdown .btn {
    --bs-btn-padding-x: 0;
    --bs-btn-padding-y: 0;
}

.tab-content .card-header .btn-tertiary {
    border: 1px solid #C7C7C7;
}

.nav-pills .nav-link.active {
    background-color: transparent;
    color: var(--intiza-blue);
}

.selectedCollapse {
    background: #F7F7F7;
    border-radius: 6px;
    padding: 11px 22px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 24px;
}

.tab-content .card-header .btn-secondary:hover {
    background-color: var(--intiza-white);
}

.selectedCollapse .quantity {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--intiza-black);
}

.clientsTabs .genericModal .btn-tertiary {
    line-height: 23px;
}

.clientsTabs #changeState .dropdown-toggle {
    width: 100%;
}

.clientsTabs #changeState .dropdown-toggle .select-intiza {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.selectedCollapse .actions {
    color: var(--intiza-blue);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
    font-size: 20px;
}

.selectedCollapse .actions button {
    border: unset;
    background: unset;
    color: unset;
}

.clientsTabs .tab-content .table .form-check-input,
.clientsTabs .tab-content .table.form-check-input:focus {
    box-shadow: unset;
    border: 1px solid #8B9195;
    border-radius: 3.59041px;
    width: 20px;
    height: 20px;
}

.clientsTabs .tab-content .table .form-check-input:checked {
    border: 1px solid var(--intiza-blue);
}

.clientsTabs .table.genericTable .state {
    padding: 6px 7px;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    width: fit-content;
}

.clientsTabs .table.genericTable .state.normal {
    background: #EAF9F8;
    color: #008D86;
}

.clientsTabs .table.genericTable .state.promise {
    background: #E1EDFF;
    color: #1422A5;
}

.clientsTabs .table.genericTable .state.informed {
    background: #E3F4DF;
    color: #0F6D00;
    ;
}

.clientsTabs .table.genericTable .state.refinancing {
    background: #FFFAED;
    color: #FF7A00;
}

.clientsTabs .table.genericTable .state.dispute {
    background: #FFE1E1;
    color: #8F0B0B;
}

/*  ------------ COLLAPSE DEBTSMENU------------ */

.debts .col.debtsDetail {
    width: calc(100% - 441px);
}

.debts .toggleMenu {
    position: absolute;
    top: 0;
    right: -13.4px;
    width: 15px;
    height: 120px;
    display: flex;
    align-items: flex-start;
}

.debts .toggleMenu button {
    display: grid;
    position: relative;
    padding: 0;
    background-color: transparent;
    border: 0;
}

.debts .toggleMenu button div {
    align-self: center;
    position: absolute;
}

.debts .toggleMenu button span {
    align-items: center;
    box-sizing: border-box;
    display: inline-flex;
    height: 12px;
    width: 12px;
    color: var(--intiza-grey2);
}

.debtsMenu .toggleMenu button:hover span {
    color: var(--intiza-blue);
}

.debts .toggleMenu button i {
    display: block;
    height: 100%;
    width: 100%;
}

.debts .toggleMenu button:is(:active, :focus) {
    outline: unset;
    box-shadow: unset;
    border: 0;
}

.debts .toggleMenu svg {
    fill: #fff;
}

/*show*/

.debtsMenu .collapse.show>div {
    width: 440px;
}

.debtsMenu .collapse.show .actions.column {
    display: none;
}

/*collapsing*/
.debtsMenu .collapsing {
    min-width: 88px;
    display: flex;
    height: auto;
    -webkit-transition: width 0.25s ease-in-out;
    transition: width 0.25s ease-in-out;
}

.debtsMenu .collapsing :is(h2, h5, p, .box, .aboutCompany, .actions, .viewData .dropdown, .viewData label) {
    visibility: hidden;
}

.debtsMenu .collapsing>div {
    width: 100%;
}

.debtsMenu .collapsing .aboutCompany {
    width: 440px;
}

.debts .collapsing .resumeCompany .btn-link {
    width: fit-content;
}

/*collapse*/
.debtsMenu .collapse:not(.show) {
    width: 88px;
    display: flex;
}

.debtsMenu .collapse:not(.show) :is(.viewData :is(.dropdown, label), .aboutCompany, .boxes, .actions:not(.actions.column)) {
    display: none;
}

.debtsMenu .collapse:not(.show) .viewData .mb-3 {
    margin-bottom: 0 !important;
}

.debtsMenu .actions.column .actions {
    flex-direction: column;
}

.debts .collapse:not(.show) .toggleMenu .btn-link {
    width: 100%;
    padding: 6px 0;
    justify-content: center;
}

/*  ------------ SEGMENTS ------------ */

.segments .container-fluid {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.segments tbody td {
    padding: 11px 8px;
}

.segments tbody td .arrowDown {
    color: var(--intiza-darkGrey);
    position: relative;
    bottom: 2px;
}

.segments td .iconFolder {
    color: var(--intiza-blue);
    font-size: 20px;
}

.segments tr .actions span {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
}

.segments tr .actions button {
    text-align: right;
    visibility: hidden;
    color: var(--intiza-darkBlue);
    font-size: 18px;
    border: 0;
    background-color: unset;
}

.segments tfoot tr:hover {
    background-color: #F2F9FD;
    cursor: auto;
}

.segments tr:hover .actions button {
    visibility: visible;
}

.segments tbody .folderName {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--intiza-black);
    position: relative;
    bottom: 3px;
}

.segments tfoot tr {
    font-weight: 600;
}


.segments .table .collapsing td {
    position: relative;
    overflow: hidden;
    transition: line-height 0.1s linear;
    transition-property: line-height, padding;
}

.segments .table .collapsing:not(.opening) td {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    line-height: 0;
    height: 0;
}

.segments .table .collapsing:not(.opening) td>* {
    display: table-column !important;
}

/* FLOATING PANEL */

div#floating-window.window-container>div.title-bar>span.windowTitle {
    background-color: var(--intiza-darkBlue);
    color: var(--intiza-white);
    text-align: left;
    padding: 13px 15px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
}


div#floating-window.window-container>div.title-bar>span.buttonContainer>span.windowButton {
    color: white;
}

div#floating-window.window-container>div.title-bar>span.icon {
    width: 0px;
}


div#floating-window.window-container {
    border-width: 0px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 15px 15px 0px;
    /*0 2px 4px -1px rgba(0, 0, 0, 0.25);*/
    border-radius: 8px 8px 0px 0px;
}

div#floating-window.window-container>div.title-bar>span.buttonContainer>span.windowButton {
    font-size: 1.3rem;
}

div.window-container {
    position: fixed;
    border: solid 1px black;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

div.window-container>div.title-bar {
    background-color: var(--intiza-darkBlue);
    display: flex;
    flex-direction: row;
    cursor: default;
}

div.window-container>div.title-bar>span.icon {
    line-height: var(--tilebar-height);
    height: var(--tilebar-height);
    width: var(--tilebar-height);
    font-size: calc(var(--tilebar-height) - 8px);
}

div.window-container>div.title-bar>span.windowTitle {
    flex-grow: 1;
    overflow: hidden;
    text-align: center;
    background-color: gray;
    color: white;
    font-size: calc(var(--tilebar-height) - 16px);
    line-height: var(--tilebar-height);
    font-weight: bold;
}

div.window-container>div.title-bar>span.windowTitle:active {
    opacity: 0.5;
}

div.window-container>div.title-bar>span.buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 15px;
    background-color: var(--intiza-darkBlue);
    gap: 0 8px;
}

div.window-container>div.title-bar>span.buttonContainer>span.windowButton {
    height: var(--tilebar-height);
    width: var(--tilebar-height);
    font-size: calc(var(--tilebar-height) - 8px);
    cursor: default;
    color: black;
    transition: 200ms ease-in-out;
}

div.window-container>div.title-bar>span.buttonContainer>span.windowButton:hover {
    color: white;
}

div.window-container>div.content {
    overflow: hidden;
    background: #FBFCFC;
    color: #333333;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.window-container .floatingBody {
    padding: 16px;
    height: 100%;
    overflow-y: auto;
    background-color: #ffffff;
}

.window-container .floatingFooter {
    position: sticky;
    bottom: 0;
    width: 100%;
    padding: 16px 16px;
    background: #F7F7F9;
    border-top: 1px solid #C7C7C7;
}


.title-bar.alert-danger,
.title-bar.alert-danger .windowTitle,
.title-bar.alert-danger .buttonContainer {
    background-color: var(--intiza-red) !important;
}

.title-bar.alert-success,
.title-bar.alert-success .windowTitle,
.title-bar.alert-success .buttonContainer {
    background-color: var(--intiza-status-green) !important;
}

/* ----- HISTORY MANAGEMENT ------ */

.historyManagement {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.historyManagementActions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.historyManagement ul.buttonTabs {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 16px;
}

.historyManagement ul.buttonTabs div {
    height: 21px;
    width: 1px;
    background-color: #D7DEEC;
}

.historyManagement .btn.history {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: var(--intiza-darkGrey);
    padding: 10px 15px;
    border-radius: 5px;
    border: 0;
}

.historyManagement .btn.history:hover {
    background-color: #F5F5F5;
}

.historyManagement .btn.history.active {
    color: #0858B7;
    background: #EAF8FA;
}

.historyManagement .historyManagementActions .btn.dropdown-toggle .select-intiza {
    background-color: var(--intiza-blue);
    color: var(--intiza-white);
    padding: 8.5px 13px;
    border: 1px solid var(--intiza-blue);
}

.historyManagement .listResults {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.h5 {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: var(--intiza-black);
}

.historyManagement .historyCard {
    padding: 18px;
    border: 1px solid #D7DEEC;
    border-radius: 6px;
    background-color: var(--intiza-white);
    width: 100%;
    display: flex;
    flex-direction: column;
}

.historyManagement .historyCard .icon {
    width: 25px;
}

.historyManagement .type {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #323236;
}

.historyManagement .creator,
.historyManagement .to {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--intiza-darkGrey);
}

.historyManagement .historyCardContent {
    display: flex;
    flex-direction: row nowrap;
    justify-content: space-between;
    margin-left: 33px;
    margin-top: 4px;
}

.historyManagement .date {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--intiza-blue);

}

.historyManagement .historyCardContent .actions {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    gap: 8px;
    color: var(--intiza-blue);
    min-width: 175px;
}

.historyManagement .historyCard .actions button {
    background-color: unset;
    border: 0;
    visibility: hidden;
}

.historyManagement .historyCard:hover {
    border: 1px solid var(--intiza-blue);
    background: #EDF9FF;
}

.historyManagement .historyCard:hover .actions button {
    visibility: visible;
    color: var(--intiza-blue)
}

.historyManagement .historyCard.email .emailBody {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--intiza-black);
}

.historyManagement .historyCard.email .emailBody p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.historyManagement .historyCard.email .subject {
    font-style: italic;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: var(--intiza-black);
    margin-bottom: 4px;
}

.historyManagement .emailBody button {
    color: var(--intiza-blue);
    background-color: unset;
    border: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: var(--intiza-blue)
}


.group-table {
    padding: 32px 24px 0px 24px;
}

.group-table .table-loader-container,
.group-table .table-loader-container .table.genericTable {
    margin-bottom: 0;
}

.ellipsis-oneline {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-placeholder {
    color: light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
}

body :nth-child(1) {
    --nth-child: 1
}

body :nth-child(2) {
    --nth-child: 2
}

body :nth-child(3) {
    --nth-child: 3
}

body :nth-child(4) {
    --nth-child: 4
}

body :nth-child(5) {
    --nth-child: 5
}

body :nth-child(6) {
    --nth-child: 6
}

body :nth-child(7) {
    --nth-child: 7
}

body :nth-child(8) {
    --nth-child: 8
}

body :nth-child(9) {
    --nth-child: 9
}

body :nth-child(10) {
    --nth-child: 10
}

body :nth-child(11) {
    --nth-child: 11
}

body :nth-child(12) {
    --nth-child: 12
}

.lds-default {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 100px;
    margin: 30px;
}

.lds-default div {
    position: absolute;
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
    background-color: var(--intiza-blue);
}

.lds-default div:nth-child(n) {
    animation-delay: calc(var(--nth-child) * 0.1s);
    top: calc(sin(var(--nth-child) * 30deg) * 50% + 50%);
    left: calc(cos(var(--nth-child) * 30deg) * 50% + 50%);
}

@keyframes lds-default {

    0%,
    20%,
    80%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.4);
    }
}


.favstar0 {
    background: url(../../assets/img/ico-star-empty.png) no-repeat;
    width: 16px;
    height: 16px;
    margin-top: 2px;
    display: inline-block;
}

.favstar0:hover {
    background: url(../../assets/img/ico-star-selected.png);
}

.favstar1 {
    background: url(../../assets/img/ico-star-filled-1.png) no-repeat;
    width: 16px;
    height: 16px;
    margin-top: 2px;
    display: inline-block;
}

.favstar2 {
    background: url(../../assets/img/ico-star-filled-2.png) no-repeat;
    width: 16px;
    height: 16px;
    margin-top: 2px;
    display: inline-block;
}

.favstar3 {
    background: url(../../assets/img/ico-star-filled-3.png) no-repeat;
    width: 16px;
    height: 16px;
    margin-top: 2px;
    display: inline-block;
}

.btn-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--intiza-blue);
}

.input-group-text {
    border-radius: 0px 4px 4px 0px;
    background: none;
    height: 40px;
}

select.form-control {
    appearance: auto;
}

.criterion .input-group input {
    line-height: 17px;
}

.criterion .input-group .input-group-text {
    background: none;
}

.fileName {
    font-size: 16px;
    font-weight: 600;
    color: var(--intiza-black);
}

.form-label-detail {
    font-weight: 700;
    font-size: 14px;
    color: var(--intiza-black);
    margin-bottom: 0.5rem;
    margin-left: auto;
    text-align: end;
}

.pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: auto;
}

.table.genericTable thead tr.cursor-default th {
    cursor: auto;
}

.cursor-sort {
    cursor: grab;
}

h6 {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: var(--intiza-darkBlue);
}

.ml-auto {
    margin-left: auto;
}

.show-child-on-hover:not(:hover) .display-when-hovering-parent {
    display: none;
}

.show-child-on-hover:not(:hover) .show-when-hovering-parent {
    visibility: hidden;
}


.alert-sm {
    padding: 2px 8px 2px 8px;
    border-radius: 5px;
    font-weight: 15px;
    font-weight: 500;
}

.alert-sm-success {
    background-color: var(--intiza-alert-sm-success-bg);
    color: var(--intiza-alert-sm-success);
}

.alert-sm-warning {
    background-color: var(--intiza-alert-sm-warning-bg);
    color: var(--intiza-status-orange);
}

.alert-sm-danger {
    background-color: var(--intiza-alert-sm-danger-bg);
    color: var(--intiza-status-red);
}

.text-grey {
    color: var(--intiza-darkGrey);
}

.bullet-point-connected {
    display: flex;
    justify-content: space-around;
    position: sticky;
    top: 40px;
    margin-top: 40px;
    --bullet-point-border-color: var(--intiza-grey2);
}

.bullet-point-connected li {
    background: linear-gradient(180deg, var(--bullet-point-border-color) 0%, var(--bullet-point-border-color) 20%, var(--bullet-point-border-color) 20%, var(--bullet-point-border-color) 80%, var(--bullet-point-border-color) 80%, var(--bullet-point-border-color) 100%);
    background-repeat: no-repeat !important;
    background-position-x: 4px !important;
    background-size: 1px 100% !important;
    line-height: 35px;
    color: var(--intiza-darkGrey);
    font-size: 14px;
    cursor: pointer;
    user-select: none;
}

.bullet-point-connected li:before {
    content: "\f111\f111";
    font-size: 6px;
    vertical-align: middle;
    color: var(--intiza-darkGrey);
    font-weight: 400;
    font-family: var(--fa-style-family, "Font Awesome 6 Pro");
    margin-left: 6.5px;
    margin-right: 18px;
}

.bullet-point-connected li::first-letter {
    font-weight: 900;
    color: #FFF;
    margin-left: -5px;
    width: 0px;
    letter-spacing: -6px;
}

.bullet-point-connected li.highlight,
.bullet-point-connected li.highlight:before,
.bullet-point-connected li.highlight::first-letter {
    color: var(--intiza-blue);
    font-weight: 600;
}

.bullet-point-connected li:first-of-type {
    background: linear-gradient(180deg, #0000 50%, var(--bullet-point-border-color) 50%) bottom,
        #00F0 top;
}

.bullet-point-connected li:last-of-type {
    background: linear-gradient(180deg, var(--bullet-point-border-color) 50%, #0000 50%);
}

.bullet-point-connected li:last-of-type:first-of-type {
    background: none;
}


.react-datepicker__day--outside-month {
    visibility: hidden;
}

.weekday-buttons-list {
    display: flex;
    gap: 10px;
}

.weekday-buttons-list span {
    border: solid 1px var(--intiza-darkGrey);
    border-radius: 100%;
    background-color: var(--intiza-background);
    text-align: center;
    vertical-align: middle;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--intiza-darkGrey);
    margin-top: 3px;
    cursor: pointer;
    user-select: none;
}

.weekday-buttons-list span.active {
    border-color: var(--intiza-blue);
    background-color: #E2EFF5;
    color: var(--intiza-blue);
}

.container-backdrop {
    position: relative;
    z-index: 9999;
}

.messageSwich {
    margin: 0 auto;
    display: grid;
    height: 100vh;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: #ededed;
}

.messageSwichContent {
    color: #6c757d;
}

/* --------------- EMAIL COMPOSE --------------- */

.tabsGroup {
    border-bottom: 1px solid var(--intiza-grey2);
    background-color: #FFFFFF;
}

.tabsGroup .btn-tab {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    padding: 20px 30px;
    border: 0;
    border-bottom: 3px solid transparent;
    border-radius: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--intiza-blue);
}

.tabsGroup .btn-tab.active,
.tabsGroup .btn-tab:hover {
    border-bottom: 3px solid var(--intiza-blue);
}

.tabBody {
    padding: 20px 30px;
    background-color: #FFFFFF;
}

.tabBody.bills .form-check-label,
.tabBody.additional .form-check-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--intiza-darkGrey);
    margin-top: 3px;
}


.tabBody .form-check-input:not([role="switch"]) {
    width: 20px;
    height: 20px;
}

form.inputWithButtons {
    background: var(--intiza-white);
    border: 1px solid #C7C7C7;
    padding: 4px 13px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    display: flex;
    flex-flow: row nowrap;
}

form.inputWithButtons input,
form.inputWithButtons button {
    border: 0;
    margin: 0;
    padding: 0;
    box-shadow: unset;
    font-size: 14px;
}

form.inputWithButtons input.form-control:focus {
    box-shadow: unset;
}

.alert-info label,
.alert-info .form-check {
    margin: 0;
}

.alert-info .form-check .form-check-label {
    font-size: 14px;
    display: flex;
    align-items: center;
}

.alert-info .btn-intiza {
    font-weight: 400;
}

.alert-info .form-check-input {
    margin-bottom: 4px;
}

.tabBody.additional .form-check-label {
    margin-bottom: 0;
}

.tabBody.additional .row label:first-child {
    color: #000000;
}

.action-table div.table-loader-container>div.tableContainer>table>thead>tr>th div {
    display: block;
}

.small-table .table.genericTable {
    min-width: auto;
}

.react-datepicker-popper {
    z-index: 5;
}

.email-ellipsis-dots {
    position: relative;
    z-index: 1;
    left: 2%;
    top: -100px;
    width: 0px;
    letter-spacing: 3px;
    font-size: 20px;
    font-weight: 800;
    width: 40px;
    height: 40px;
    padding-left: 19px;
    padding-bottom: 19px;
}

.mce-editor-prev-message-margin .tox-edit-area {
    margin-bottom: 60px;
}

.nav-sidebar {
    min-height: calc(100vh - 95px);
    background-color: var(--intiza-white);
    padding: 16px 0 24px 0;
    display: flex;
    flex-direction: column;
    flex-shrink: unset;
}

.nav-sidebar li button {
    padding: 16px 8px 16px 50px;
    white-space: nowrap;
    color: var(--intiza-darkGrey);
    font-size: 15px;
    font-weight: 500;
    line-height: 21px;
    border-right: 3px solid var(--intiza-white);
    border-radius: 0;
    width: 100%;
}

.nav-sidebar li button:hover {
    color: var(--intiza-blue);
}

.nav-sidebar li button.active {
    color: var(--intiza-blue);
    font-weight: 600;
    border-right: 3px solid var(--intiza-blue);
    background-color: #E5F1F8;
}

.tab-content .card h2 {
    font-weight: 600;
    font-size: 21px;
    line-height: 21px;
    color: var(--intiza-black);
}

.tab-content .card h3 {
    color: var(--intiza-black);
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
}

.companyLogo {
    position: relative;
    cursor: pointer;
}

.companyLogo div:first-child {
    width: 124px;
    height: 124px;
    overflow: hidden;
}

.companyLogo img {
    width: 100%;
    height: 100%;
    border: 1px solid #eee;
    object-fit: cover;
}

.companyLogo .upload {
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--intiza-blue);
    color: var(--intiza-white);
}

.configuration button.edit,
.table.optionsList button.edit {
    border: 0;
    background-color: unset;
    visibility: hidden;
    width: 100%;
}

.configuration table tr:hover button.edit,
.table.optionsList tr:hover button.edit {
    visibility: visible;
}

.configuration .edit i.fa-pen,
.table.optionsList .edit i.fa-pen {
    color: var(--intiza-blue);
}

.configuration .table-striped tbody td {
    --bs-table-accent-bg: var(--intiza-white);
}

.configuration .table i.fa-check,
.table.optionsList i.fa-check {
    color: #2AADA6;
}

i.fa-square-question {
    color: var(--intiza-blue);
}

.optionsList .form-check {
    padding: 8px;
    padding-left: 32px;
    padding-right: 0;
}

.optionsList td div {
    height: 40px;
}

.optionsList .addRow:hover {
    background-color: unset;
}

.tags input:disabled,
.tags input:disabled {
    border-color: var(--intiza-white);
    background: var(--intiza-white);
}

.tags tr:hover input:disabled {
    border-color: #F2F9FD;
    background: transparent;
}

.tags .onEdit {
    background-color: #F2F9FD;
}

/* ------ IMPORT TEMPLATES ----- */

.collapsableFolder {
    cursor: pointer;
}

.collapsableFolder td {
    padding: 11px 8px;
    font-weight: 500;
}

.collapsableFolder td .arrowDown {
    color: var(--intiza-darkGrey);
    position: relative;
    bottom: 2px;
}

.collapsableFolder td .iconFolder {
    color: var(--intiza-blue);
    font-size: 20px;
}

.collapsableFolder tr .actions span {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
}

.collapsableFolder tr .actions button {
    text-align: right;
    visibility: hidden;
    font-size: 18px;
    border: 0;
    background-color: unset;
}

.collapsableFolder tr .actions i {
    color: var(--intiza-blue);
}

.collapsableFolder tr:hover .actions button {
    visibility: visible;
}

.collapsableFolder .folderName {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--intiza-black);
    position: relative;
    bottom: 3px;
}

.collapsableFolder .collapsing td {
    position: relative;
    overflow: hidden;
    transition: line-height 0.1s linear;
    transition-property: line-height, padding;
}

.collapsableFolder .collapsing:not(.opening) td {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    line-height: 0;
    height: 0;
    transition: line-height 0.1s linear;
}

.collapsableFolder .collapsing:not(.opening) td>* {
    display: none !important;
    transition: line-height 0.1s linear;
}

.dashboard-panel tr th {
    background-color: transparent !important;
    min-width: unset !important;
}

.dashboard-panel tr td {
    max-width: unset !important;
    font-weight: 400;
}

/* ------ REPORTS ----- */

.reports h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 30.045px;
    padding: 24px 0 24px 8px;
}

.tree {
    height: fit-content;
    display: flex;
    padding-bottom: 40px;
}

.tree .withChildren {
    height: 139px;
}

.tree .brackets {
    height: auto;
    padding-left: 24px;
}

.tree .brackets img {
    height: 100%;
    object-fit: contain;
    user-select: none;
}

.tree .card {
    width: 400px;
    padding: 20px;
    min-height: 140px;
}

.tree .card .row:first-child {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid var(--intiza-grey2);
    font-size: 16px;
    font-weight: 700;
    color: var(--intiza-black);
}

.tree .card .row:nth-child(2) {
    padding-bottom: 8px;
}

.tree .card.blue {
    border-color: var(--intiza-blue);
    background-color: #E9F7FF;
}

.tree .card.blue .row:first-child {
    border-bottom: 1px solid var(--intiza-blue);
}

.tree .card.red {
    border-color: #FF6666;
    background-color: #FFDEDE;
}

.tree .card.red .row:first-child {
    border-bottom: 1px solid #FF6666;
}

.tree .card .row:not(:first-child) {
    font-size: 14px;
}

.cursorDefault {
    cursor: default;
}

.progress {
    height: 10px;
    --bs-progress-bar-bg: var(--intiza-blue);
}

.progress .bg-red {
    background-color: var(--intiza-red);
}

/* ------ IMPORTS & PROCESS LOG ----- */

.logs h2 {
    font-weight: 600;
    font-size: 21px;
    line-height: 21px;
    color: #333333;
    color: var(--intiza-black);
}

.logs .fa-trash-can,
.logs .fa-search,
.logs .fa-arrow-down-to-line {
    font-size: 18px;
    padding: 0 16px;
}

.logs .table.genericTable tbody tr td {
    max-width: unset;
}

.logs td a:hover {
    color: inherit;
}

/* ------ DATERANGE ----- */

.dateRange {
    height: 100%;
}

.daterangepicker {
    font-family: 'lato';
}

.daterangepicker .ranges ul {
    width: auto;
}

.daterangepicker .ranges li.active {
    background-color: transparent;
    color: var(--intiza-black);
}


.daterangepicker .ranges li {
    padding: 5px 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    color: var(--intiza-black);
    border-bottom: 1px solid #E7E7E7;
}

.daterangepicker .ranges li:last-child {
    border-bottom: 0px;
}

.daterangepicker .ranges li.active:hover,
.daterangepicker .ranges li:hover {
    background: #E3F5FF;
}

.daterangepicker.show-calendar .drp-buttons {
    display: flex;
    justify-content: flex-end;
}

.daterangepicker .drp-buttons .btn {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 12px;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
    background-color: #E3F5FF;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left {
    height: 323px;
}

.daterangepicker .calendar-table th {
    font-size: 13px;
}

.daterangepicker .drp-calendar.left {
    padding: 12px 0 12px 12px;
}

.daterangepicker .drp-calendar.right {
    padding: 12px;
}

.tableContainer .table thead tr th.header-numeric {
    text-align: right !important;
}

.tableContainer .table thead tr th.header-numeric:has(i) {
    position: relative;
}

.h2 {
    font-weight: 600;
    font-size: 21px;
    line-height: 21px;
    color: var(--intiza-black);
}

.loading {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--intiza-blue);
}

.loading p {
    font-size: 18px;
    font-weight: 500;
}

.importContainer {
    padding: 32px 0;
    display: flex;
    flex-direction: column;
    gap: 24px 0;
}

.importContainer .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    border-top: 1px solid #EAEAEA;
    padding-top: 32px;
}

.accordion-item {
    border: 0;
}

.table thead th.txt-blue {
    color: #4082B6;
}

.table thead th.txt-red {
    color: #D0021B;
}

.accordion-button::before {
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    content: "";
    background-image: url(../../assets/img/accordion-icon.svg);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    -webkit-transition: var(--bs-accordion-btn-icon-transition);
    transition: var(--bs-accordion-btn-icon-transition);
    margin-right: 10px;
}

.accordion-button:not(.collapsed)::before {
    background-image: url(../../assets/img/accordion-icon.svg);
    -webkit-transform: var(--bs-accordion-btn-icon-transform);
    transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button:not(.collapsed) {
    color: var(--intiza-black);
    background: transparent;
    border-bottom: 1px solid #EAEAEA;
}


.accordion-button::after,
.accordion-button:not(.collapsed)::after {
    content: unset;
}

.accordion-button {
    color: var(--intiza-black);
    font-size: 16px;
    font-weight: 600;
    line-height: 21.045px;
    padding: 20px;
    border: 0;
    border-bottom: 1px solid #EAEAEA;
}

.accordion-button:focus {
    outline: none;
    box-shadow: none;
    border-color: #EAEAEA;
}

.white-space-nowrap {
    white-space: nowrap;
}

.bg-danger-light {
    background: #FCC;
    --bs-table-bg: #FFDADA;
}


/* ----- EMAIL EDITO -----*/

.rdw-editor-wrapper {
    display: flex;
    flex-direction: column-reverse;
    border: 1px solid #C7C7C7;
    border-radius: 6px;
}

.rdw-editor-wrapper .rdw-editor-main {
    height: 180px;
}

.rdw-editor-wrapper .rdw-editor-toolbar {
    border: 0;
    border-top: 1px solid #C7C7C7;
    margin-bottom: 0;
    padding: 10px 6px;
    border-radius: 0 0 6px 6px;
}

.rdw-editor-toolbar>div {
    height: 30px;
    margin-bottom: 0;
}

.public-DraftEditor-content {
    overflow-y: auto;
    padding: 16px;
}

.public-DraftStyleDefault-block:first-child {
    margin: 0;
}

.rdw-editor-wrapper .rdw-fontsize-wrapper {
    margin-bottom: 0;
    border-radius: 3px;
}

.rdw-editor-wrapper .rdw-dropdown-selectedtext {
    background-color: #f7f7f7;
    width: 130px;
}

.rdw-editor-wrapper .rdw-dropdown-selectedtext::after {
    content: "\f078";
    font-weight: 900;
    font-family: "Font Awesome 6 Pro";
    font-size: 12px;
    width: 8px;
    position: absolute;
    right: 12px;
}

.rdw-editor-wrapper .rdw-dropdown-selectedtext {
    border-radius: 3px;
    padding: 0 8px;
}

.rdw-editor-wrapper .rdw-dropdown-selectedtext .rdw-dropdown-carettoclose,
.rdw-editor-wrapper .rdw-dropdown-selectedtext .rdw-dropdown-carettoopen {
    display: none;
}

.rdw-editor-wrapper .rdw-dropdown-selectedtext:hover,
.rdw-editor-wrapper .rdw-colorpicker-wrapper:hover {
    background-color: #cce2fa;
    color: var(--intiza-black);
}

.rdw-editor-wrapper .rdw-dropdown-wrapper,
.rdw-editor-wrapper .rdw-dropdown-wrapper:hover {
    border: 0;
    box-shadow: unset;
}

.rdw-editor-wrapper .rdw-dropdown-optionwrapper {
    top: -262px;
    border-radius: 6px;
    min-width: 100%;
    box-shadow: 0 0 2px 0 rgba(34, 47, 62, .2), 0 4px 8px 0 rgba(34, 47, 62, .15);
    border: 0;
    background-color: var(--intiza-white);
    overflow: hidden;
    padding: 4px 6px;
}

.rdw-editor-wrapper .rdw-dropdown-optionwrapper:hover {
    box-shadow: 0 0 2px 0 rgba(34, 47, 62, .2), 0 4px 8px 0 rgba(34, 47, 62, .15);
    border: 0;
}


.rdw-editor-wrapper .rdw-dropdown-optionwrapper li {
    padding: 4px 8px;
    justify-content: flex-start;
    font-size: 14px;
    line-height: 24px;
    border-radius: 3px;
}

.rdw-editor-wrapper .rdw-dropdown-optionwrapper li:hover {
    background-color: #cce2fa;
}

.rdw-editor-wrapper .rdw-dropdownoption-active {
    background-color: transparent;
}

.rdw-list-wrapper .rdw-option-wrapper:nth-child(2) {
    display: none;
}

.rdw-dropdownoption-default.rdw-dropdownoption-active::after {
    content: "âœ“";
    color: var(--intiza-black);
    display: inline-block;
    width: 16px;
    height: 16px;
    font-weight: 900;
    font-size: 18px;
    position: absolute;
    right: 12px;
    transform: translateY(-4.5px);
}

.rdw-editor-wrapper .rdw-colorpicker-wrapper {
    border-radius: 3px;
}

.rdw-editor-wrapper .rdw-colorpicker-modal-header {
    display: none;
}

.rdw-editor-wrapper .rdw-colorpicker-modal-options {
    overflow: hidden;
}

.rdw-editor-wrapper .rdw-colorpicker-wrapper>.rdw-option-wrapper {
    box-shadow: unset;
    border: 0;
    background-color: transparent;
    padding: 0 17px 0 9px;
    margin: 0;
    border-radius: 3px;
}

.rdw-colorpicker-wrapper .rdw-option-wrapper img {
    display: none;
}

.rdw-colorpicker-wrapper>.rdw-option-wrapper::before {
    content: url(../../assets/img/text-color-icon.svg);
    width: 12px;
    height: 13px;
    transform: translateY(-2px);
}

.rdw-colorpicker-wrapper>.rdw-option-wrapper::after {
    content: "\f078";
    font-weight: 900;
    font-family: "Font Awesome 6 Pro";
    font-size: 12px;
    width: 8px;
    position: relative;
    right: -5px;
}

.rdw-editor-wrapper .rdw-colorpicker-modal {
    top: -242px;
    border-radius: 6px;
    min-width: 100%;
    box-shadow: 0 0 2px 0 rgba(34, 47, 62, .2), 0 4px 8px 0 rgba(34, 47, 62, .15);
    border: 1px solid transparent;
    background-color: var(--intiza-white);
    overflow: hidden;
    padding: 0;
    width: 242px;
    height: auto;
}

.rdw-editor-wrapper .rdw-colorpicker-modal-options {
    height: auto;
    margin: 0;
}

.rdw-editor-wrapper .rdw-colorpicker-modal-options .rdw-colorpicker-option {
    margin: 0;
    width: 30px;
    height: 30px;
    background-color: transparent;
}

.rdw-colorpicker-option-active .rdw-colorpicker-cube:before {
    content: "âœ“";
    font-weight: 900;
    text-shadow: -1px 0 var(--intiza-black), 0 1px var(--intiza-black), 1px 0 var(--intiza-black), 0 -1px var(--intiza-black);
    font-size: 20px;
    color: var(--intiza-white);
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.rdw-editor-wrapper .rdw-colorpicker-modal-options .rdw-colorpicker-option:hover {
    box-shadow: unset;
}

.rdw-editor-wrapper .rdw-colorpicker-option .rdw-colorpicker-cube {
    width: 100%;
    height: 100%;
    transition: transform 0.15s;
    border: 0;
    box-shadow: unset;
    position: relative;
}

.rdw-editor-wrapper .rdw-colorpicker-option .rdw-colorpicker-cube:hover {
    transform: scale(0.8);
}

.rdw-inline-wrapper>.rdw-option-wrapper,
.rdw-list-wrapper>.rdw-option-wrapper,
.rdw-text-align-wrapper>.rdw-option-wrapper,
.rdw-link-wrapper>.rdw-option-wrapper,
.rdw-remove-wrapper>.rdw-option-wrapper {
    margin: 0 1px;
    padding: 5px 9px;
    height: 100%;
    border: 0;
}

.rdw-inline-wrapper .rdw-option-wrapper:nth-child(1) img,
.rdw-inline-wrapper .rdw-option-wrapper:nth-child(2) img {
    padding-bottom: 1px;
}

.rdw-inline-wrapper .rdw-option-wrapper:nth-child(2),
.rdw-inline-wrapper .rdw-option-wrapper:nth-child(3) {
    padding: 5px 7px;
}

.rdw-inline-wrapper>.rdw-option-wrapper:hover,
.rdw-list-wrapper>.rdw-option-wrapper:hover,
.rdw-text-align-wrapper>.rdw-option-wrapper:hover,
.rdw-link-wrapper>.rdw-option-wrapper:hover,
.rdw-remove-wrapper>.rdw-option-wrapper:hover {
    background-color: #cce2fa;
    border-radius: 3px;
    box-shadow: none;
}

.rdw-inline-wrapper .rdw-option-active,
.rdw-list-wrapper .rdw-option-active,
.rdw-text-align-wrapper .rdw-option-active,
.rdw-link-wrapper .rdw-option-active,
.rdw-remove-wrapper .rdw-option-active {
    box-shadow: none;
    background-color: #a6ccf7;
    border-radius: 3px;
}

.rdw-editor-wrapper .rdw-list-wrapper {
    margin: 0;
}

.rdw-list-wrapper .rdw-option-wrapper {
    padding: 5px 7px;
}

.rdw-text-align-wrapper img,
.rdw-link-wrapper img,
.rdw-remove-wrapper img {
    width: 14px;
}

.rdw-text-align-wrapper .rdw-option-wrapper,
.rdw-link-wrapper .rdw-option-wrapper,
.rdw-remove-wrapper .rdw-option-wrapper {
    padding: 5px 8px;
    border: 0;
}

.rdw-link-wrapper .rdw-option-disabled {
    cursor: not-allowed;
}

.rdw-link-wrapper .rdw-option-disabled:hover {
    background-color: transparent;
}

.rdw-link-decorator-wrapper a {
    color: #0d6efd;
    text-decoration: underline;
}

.rdw-link-wrapper .rdw-link-modal {
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 6px;
    padding: 12px 6px;
    box-shadow: 0 0 2px 0 rgba(34, 47, 62, .2), 0 4px 8px 0 rgba(34, 47, 62, .15);
    top: -240px;
    height: auto;
}

.rdw-link-modal .rdw-link-modal-buttonsection {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    margin: unset;
}

.rdw-link-modal-buttonsection .rdw-link-modal-btn:nth-child(1) {
    background-color: var(--intiza-blue);
    color: var(--intiza-white);
    border-radius: 3px;
    border-color: transparent;
}

.rdw-link-modal-buttonsection .rdw-link-modal-btn:nth-child(2) {
    background-color: var(--intiza-white);
    color: var(--intiza-red);
    border-radius: 3px;
    border-color: transparent;
}

.rdw-link-modal-buttonsection .rdw-link-modal-btn:nth-child(1):hover {
    box-shadow: unset;
    background-color: var(--intiza-darkBlue);
}

.rdw-link-modal-buttonsection .rdw-link-modal-btn:nth-child(2):hover {
    box-shadow: unset;
}

.rdw-link-modal input {
    margin: 0;
    height: 32px;
}

.rdw-link-modal> :nth-child(3) {
    margin: 16px 0 0 0;
}

.rdw-link-modal-target-option {
    margin: 4px 0 0 4px;
    display: flex;
    align-items: center;
}

#dropdown-menu-conciliaciones {
    left: auto;
}

.table tfoot td {
    background-color: rgb(228, 244, 255);
}

.table.genericTable tfoot tr td.long-text {
    width: 250px;
    min-width: 250px;
}

.floatingBody .tableContainer.sticky-top {
    top: -25px;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.overflow-x-auto {
    overflow-x: auto;
}

@media (width < 1372px) {

    #invoice-table-header .col.d-flex .btn,
    #invoice-table-header .col.d-flex .select-intiza {
        font-size: 13px;
    }

    #invoice-table-header .gap-3 {
        grid-gap: .5rem !important;
        gap: .5rem !important;
    }
}

.table thead tr th:hover {
    max-width: fit-content !important;
}

.rrsTable thead th,
.rrsTable tbody td,
.rrsTable tbody tr:hover {
    border-bottom: 0px;
    background-color: transparent;
}

.rrsTable td:nth-of-type(n+2) {
    background-color: rgb(228, 244, 255);
}

.rrsTable td:nth-of-type(n+1),
.rrsTable th:nth-of-type(3n+1) {
    border-right: 2px solid white;
}

.rrsTable th:nth-of-type(3n+1),
.rrsTable td:nth-of-type(3n+1) {
    border-right: 10px solid white;
}

.rrsTable thead th {
    background-color: #d0ecfb;
    padding: 10px 10px 10px 8px;
    border-bottom: 2px solid #fff;
    color: var(--intiza-black);
}

.rrsTable thead th:nth-of-type(1) {
    background-color: transparent;
}