@charset "UTF-8";

@font-face {
  font-family: "Avenir Next";
  src: url("https://cdn.intiza.com/content/v5/fonts/AvenirNext-Bold.woff2") format("woff2"), url("https://cdn.intiza.com/content/v5/fonts/AvenirNext-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir Next Demi";
  src: url("https://cdn.intiza.com/content/v5/fonts/AvenirNext-DemiBold.woff2") format("woff2"), url("https://cdn.intiza.com/content/v5/fonts/AvenirNext-DemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir Next";
  src: url("https://cdn.intiza.com/content/v5/fonts/AvenirNext-Heavy.woff2") format("woff2"), url("https://cdn.intiza.com/content/v5/fonts/AvenirNext-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir Next";
  src: url("https://cdn.intiza.com/content/v5/fonts/AvenirNext-Regular.woff2") format("woff2"), url("https://cdn.intiza.com/content/v5/fonts/AvenirNext-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir Next";
  src: url("https://cdn.intiza.com/content/v5/fonts/AvenirNext-Medium.woff2") format("woff2"), url("https://cdn.intiza.com/content/v5/fonts/AvenirNext-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir Next Condensed Demi";
  src: url("https://cdn.intiza.com/content/v5/fonts/AvenirNextCondensed-DemiBold.woff2") format("woff2"), url("https://cdn.intiza.com/content/v5/fonts/AvenirNextCondensed-DemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir Next Condensed";
  src: url("https://cdn.intiza.com/content/v5/fonts/AvenirNextCondensed-Heavy.woff2") format("woff2"), url("https://cdn.intiza.com/content/v5/fonts/AvenirNextCondensed-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir Next Condensed";
  src: url("https://cdn.intiza.com/content/v5/fonts/AvenirNextCondensed-Bold.woff2") format("woff2"), url("https://cdn.intiza.com/content/v5/fonts/AvenirNextCondensed-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir Next Condensed";
  src: url("https://cdn.intiza.com/content/v5/fonts/AvenirNextCondensed-Medium.woff2") format("woff2"), url("https://cdn.intiza.com/content/v5/fonts/AvenirNextCondensed-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir Next Condensed";
  src: url("https://cdn.intiza.com/content/v5/fonts/AvenirNextCondensed-Regular.woff2") format("woff2"), url("https://cdn.intiza.com/content/v5/fonts/AvenirNextCondensed-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir Next Condensed Ultra";
  src: url("https://cdn.intiza.com/content/v5/fonts/AvenirNextCondensed-UltraLight.woff2") format("woff2"), url("https://cdn.intiza.com/content/v5/fonts/AvenirNextCondensed-UltraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

body.old-page {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #212121;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.old-page b,
.old-page strong {
  font-weight: 700;
}

.old-page header {
  background: linear-gradient(#77b0dc, #86c7ea);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.6);
  height: 86px;
  position: relative;
}

.old-page .navbar-dark .navbar-brand {
  padding: 0;
}

.old-page .navbar-dark .navbar-brand img {
  height: 17px;
}

.old-page .navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.old-page .navbar-dark #navbar a {
  font-size: 12px;
}

.old-page .head {
  background-color: #f9fbfc;
  /* padding-bottom: 20px;*/
  padding-top: 10px;
  padding-bottom: 10px;
}

.old-page .navbar-nav .dropdown-menu,
.old-page #main-navigation .dropdown-menu a {
  border-radius: 0;
  width: 336px;
  padding: 15px 30px;
  border-bottom: 2px solid #fafafa;
}

.old-page .navbar-nav .dropdown-menu {
  top: 27px;
}

.old-page #main-layout-container {
  min-height: calc(100vh - 175px);
}

.old-page .navbar-nav .dropdown-menu a,
.old-page #main-navigation .dropdown-menu a {
  width: 336px;
  padding: 15px 30px;
  border-bottom: 2px solid #fafafa;
}

.old-page .navbar-nav .dropdown-menu a:last-child,
.old-page #main-navigation .dropdown-menu a:last-child {
  border-bottom: none;
}

.old-page .dropdown-divider {
  margin: 0;
}

.old-page .dropdown-menu {
  font-size: 14px;
  padding: 0 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.old-page .progress-bar-vertical {
  width: 80px;
  min-height: 200px;
  margin-right: 20px;
  vertical-align: bottom;
  display: flex;
  flex-direction: column-reverse;
  margin: 0 auto;
}

.old-page .progress-bar-vertical .progress-bar {
  width: 100%;
  height: 0;
  -webkit-transition: height 0.6s ease;
  -o-transition: height 0.6s ease;
  transition: height 0.6s ease;
  display: block;
}

.old-page .progress.progress-transparent {
  background-color: transparent;
}

.old-page .bb-2px {
  border-bottom: 2px solid #ccc;
}

.old-page .bt-1px {
  border-top: 1px solid #ccc;
}

.old-page .hide {
  display: none;
}

.old-page .card-header {
  background-color: transparent;
  border-bottom: 0;
}

.old-page a.dropdown-item.text-primary {
  color: #4082B6;
}

.old-page .text-primary {
  color: #4082B6;
}

.old-page .text-change-account,
.old-page .text-change-account:hover {
  color: #4082B6;
}

.old-page .bg-white {
  background-color: #FFF;
}

.old-page .bg-light-blue {
  background-color: #ddedf9;
}

.old-page .bg-light-light-blue {
  background-color: #f9fbfc;
}

.old-page .bg-light-blue2 {
  background-color: #F2F9FD;
}

.old-page .bg-gray {
  background-color: #cecece;
}

.old-page .bg-light-gray {
  background-color: #f4f4f4;
}

.old-page .bg-light-light-gray {
  background-color: #fbfbfb;
}

.old-page .bg-gray2 {
  background-color: #fafafa;
}

.old-page .bg-transparent {
  background-color: transparent;
}

.old-page .bg-gray2.txt-blue {
  opacity: 0.5;
}

.old-page .alert {
  border-radius: 0;
}

.old-page .btn-underline {
  text-decoration: underline;
}

.old-page .btn {
  border-radius: 0;
  font-size: 13px;
}

.old-page .btn-group-sm>.btn,
.old-page .btn-sm {
  border-radius: 0;
}

.old-page .btn-primary {
  background-color: #4082B6;
  border-color: #4082B6;
}

.old-page .btn-primary:hover {
  background-color: #0069d9;
  border-color: #0062cc;
}

.old-page .btn-primary.disabled,
.old-page .btn-primary:disabled {
  color: #fff;
  background-color: #E0E0E0;
  border-color: #E0E0E0;
}

.old-page .btn-link:hover,
.old-page .btn-link:focus,
.old-page .btn-link:visited {
  text-decoration: none;
}

.old-page .btn-link.add-filter,
.old-page .btn-link.add-filter:hover {
  color: #4082B6;
}

.old-page .input-group-append .btn.btn-primary.btn-keyword-filter {
  border-bottom-width: 0px;
}

.old-page .input-group .form-control {
  padding: 18px 6px;
}

.old-page .pagination-sm .page-link {
  padding: 0.575rem 0.75rem;
}

.old-page .b-0 {
  border: none;
}

.old-page .progress {
  background-color: #E0E0E0;
}

.old-page .progress-bar {
  background-color: #4A90E2;
}

.old-page .table tr.py-2 td,
.old-page .table tr.py-2 th {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.old-page table .fa-address-book {
  font-size: 14px;
  color: #616161;
}

.old-page .is-link:hover {
  cursor: pointer;
}

.old-page .table td,
.old-page .table th {
  border-top-color: #cccccc;
}

.old-page .table tr:last-child td {
  border-bottom: 1px solid #cccccc;
}

.old-page thead {
  color: #4A4A4A;
}

.old-page thead th.small {
  font-size: 10px;
  font-weight: bold;
}

.old-page a {
  color: #4082B6;
}

.old-page a:hover {
  text-decoration: none;
}

.old-page .btn-link {
  color: #616161;
  text-decoration: none;
}

.old-page .btn-link:hover {
  color: #0056b3;
}

.old-page .txt-blue {
  color: #4082B6;
}

.old-page .txt-red {
  color: #D0021B;
}

.old-page .txt-light-blue {
  color: #ddedf9;
}

.old-page .text-gray {
  color: #cecece;
}

.old-page .text-dark-gray {
  color: #616161;
}

.old-page .font-normal {
  font-weight: normal;
}

.old-page .required {
  color: #f45858;
}

.old-page .input {
  padding: 0.75rem;
}

.old-page .table thead th,
.old-page .table thead td {
  text-align: left;
  border-top: none;
  /* font-weight: bold; */
}

.old-page .table.table-hover tr:hover {
  background-color: #fbf4c2;
}

.old-page .form-check-input {
  position: relative;
  margin: 0;
}

.old-page header .navbar {
  border-bottom: 1px solid #77b0dc;
  margin-bottom: 5px;
  padding: 0;
}

.old-page header #main-navigation .nav-tabs {
  border-bottom: 1px solid transparent;
}

.old-page header #main-navigation .show .nav-link {
  color: #4082B6;
}

.old-page header #main-navigation .nav-tabs .nav-link:hover {
  border: none;
}

.old-page header #main-navigation .nav-tabs .nav-link.active {
  color: #212121;
  background-color: #f9fbfc;
  border: none;
}

.old-page header #main-navigation .nav-tabs .nav-link.active:hover {
  background-color: #f9fbfc;
}

.old-page header #secondary-navigation .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.old-page header #main-navigation .company {
  width: 218px;
  border-right: 1px solid #77b0dc;
  margin-right: 20px;
}

.old-page header #main-navigation .company .nav-link {
  padding: 0.75rem 1rem;
  color: #4082B6;
  font-weight: 600;
  margin-top: 0;
}

.old-page main .navbar {
  border-bottom: 1px solid #FAFAFA;
  margin-bottom: 20px;
}

.old-page .head {
  color: #616161;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
}

.old-page .head .text-muted {
  font-weight: 600;
}

.old-page .head .h3,
.old-page .head .navbar .h3 {
  font-size: 20px;
  /* font-weight: bold; */
  color: #212121;
  margin-bottom: 10px;
}

.old-page .head .fa-2x,
.old-page .head .navbar .fa-2x {
  font-size: 24px;
}

.old-page .card {
  border-radius: 0;
}

.old-page .bb-1 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.old-page .bb-2 {
  border-bottom: 2px solid rgba(0, 0, 0, 0.125);
}

.old-page label {
  font-weight: bold;
}

.old-page .form-control {
  border-radius: 0;
  font-size: 12px;
  width: 100%;
}

.old-page .apply-to .form-control {
  width: 350px;
}

.old-page .payments-applied h5 {
  margin-top: 50px;
}

.old-page .payments-applied .dropdown-toggle::after {
  float: right;
  margin-top: 8px;
}

.old-page .payments-applied .dropdown-toggle,
.old-page .payments-applied .dropdown-menu {
  width: 213px;
}

.old-page .payments-applied .dropdown-menu {
  height: 150px;
  overflow-y: auto;
}

.old-page .payments-applied label {
  font-weight: normal;
}

.old-page .payments-applied .form-check {
  padding-left: 0.75rem;
  margin-bottom: 5px;
}

.old-page .payments-applied li {
  border-bottom: 1px solid #d2d2d2;
  padding: 10px 0;
  color: #616161;
}

.old-page .payments-applied li:last-child {
  border-bottom: none;
}

.old-page .payments-applied li span {
  font-weight: bold;
  color: #000;
}

.old-page .payments-applied dl {
  margin-bottom: 0;
}

.old-page .payments-applied dl dt {
  font-weight: normal;
  color: #9e9e9e;
}

.old-page .payments-applied dl dd {
  margin-bottom: 0.1rem;
}

.old-page .form-control.search {
  padding-right: 30px;
}

.old-page .payments-applied .search.active {
  background-color: #fafafa;
}

.old-page .filters {
  margin-bottom: -1px;
}

.old-page .filters .actions {
  line-height: 38px;
}

.old-page .filters .filters-applied {
  line-height: 33px;
  padding-left: 10px;
}

.old-page .filters .accordion .card:last-of-type {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.old-page .filters ul {
  margin-bottom: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding-bottom: 10px;
}

.old-page .filters li {
  /* border-bottom: 1px solid #d2d2d2; */
  /* padding: 5px; */
  color: #616161;
  /* width: 720px; */
  margin: 0 30px 5px 0;
  border: 1px solid #bcd8ee;
  background-color: #F2F9FD;
  padding-right: 30px;
  position: relative;
}

.old-page .filters.filters-wide li {
  margin-right: 0;
}

.old-page .filters li:last-child {
  margin-bottom: 0;
}

.old-page .filters li>div {
  padding: 5px 10px;
}

.old-page .filters li .dropdown-toggle {
  background-color: transparent;
  border: none;
  color: #212121;
  text-align: left;
  width: 100%;
  height: 38px;
  line-height: 38px;
  padding-top: 0;
  padding-bottom: 0;
}

.old-page .filters li .dropdown-toggle::after {
  top: 50%;
  /* line-height: 35px; */
  position: absolute;
  right: 10px;
}

.old-page .filters li div.delete {
  line-height: 35px;
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  padding: 5px 40px 5px 0;
  text-align: right;
}

.old-page .filters .dropdown-menu {
  width: 230px;
  padding: 0.5rem;
}

.old-page .filters .dropdown-menu p {
  margin-bottom: 5px;
  margin-top: 5px;
}

.old-page .filters .dropdown-menu .dropdown-item {
  padding-left: 0;
  padding-right: 0;
}

.old-page .filters .dropdown-menu .dropdown-item-inner {
  padding-left: 2rem;
}

.old-page .dropdown-menu {
  border-radius: 0;
}

.old-page .nav-container .dropdown-item {
  padding: 1rem 1.5rem;
  border-bottom: 2px solid #fafafa;
}

.old-page .nav-container .dropdown-item.text-primary {
  padding: 1rem 1.5rem;
  border-bottom: 0px solid #fafafa;
}

.old-page .btn-outline-control {
  border-color: #ccc;
  font-size: 12px;
  background-color: #fff;
  color: #495057;
  padding: 0.375rem 0.75rem;
  height: calc(2.25rem + 2px);
}

.old-page .with-sidebar .main-panel {
  width: calc(100% - 260px);
  float: left;
}

.old-page .with-sidebar .sidebar {
  width: 245px;
  float: right;
  margin-right: 15px;
}

.old-page .growl {
  position: fixed;
  top: 65px;
  left: 15px;
  right: 15px;
  z-index: 1090;
}

.old-page .growl {
  left: auto;
  width: 400px;
}

.old-page .growl.growl-static {
  position: fixed;
  top: 10px;
  right: 10px;
}

.old-page .dropdown-toggle.no-caret::after {
  border: none;
}

.old-page .table.table-outline {
  border: 1px solid #FAFAFA;
}

.old-page .table tfoot td,
.old-page .table tfoot th {
  border: none;
}

.old-page .card-payments .card-body {
  padding: 1.25rem 0;
}

.old-page .card-payments .card-body tr td:first-child {
  padding-left: 1.55rem;
}

.old-page .accordion .card {
  margin-bottom: 20px;
}

.old-page .accordion .card .card-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.old-page .accordion.accordion-group .card {
  margin-bottom: -1px;
}

.old-page .accordion.accordion-group .card:last-child {
  border-bottom: 0;
  margin-bottom: 20px;
}

.old-page .text-sm {
  font-size: 12px;
}

.old-page .text-xl {
  font-size: 19px;
}

.old-page .text-xxl {
  font-size: 24px;
}

.old-page .text-xxxl {
  font-size: 42px;
}

.old-page .bx-0 {
  border-left: 0;
  border-right: 0;
}

.old-page .bt-0 {
  border-top: 0;
}

.old-page .resume-default .card.balance {
  background-color: #cecece;
}

.old-page .resume-in-progress .card.balance {
  background-color: #9ec6e5;
}

.old-page .resume-success .card.balance {
  background-color: #8edb71;
}

.old-page .resume-error .card.balance {
  background-color: #f45858;
}

.old-page .card.card-payments .fas {
  color: #000;
}

.old-page .card.card-payments .card-header {
  cursor: pointer;
}

.old-page .card.card-payments thead {
  color: #616161;
}

.old-page .card.card-payments .card-header thead td {
  padding-bottom: 0;
  color: #9E9E9E;
  font-weight: 500;
}

.old-page .card.card-payments .card-body {
  padding-bottom: 0;
}

.old-page .card.card-payments .card-body thead td {
  border: none;
  color: #9E9E9E;
  font-weight: 500;
}

.old-page .card.card-payments .table td,
.old-page .card.card-payments .table th {
  border-top: 1px solid #cecece;
}

.old-page .card.card-payments .card-header .table td,
.old-page .card.card-payments .card-header .table th {
  border: none;
}

.old-page .card.card-payments .card-header.card-header-collapsed tbody td,
.old-page .card.card-payments .card-header.card-header-collapsed .invoices,
.old-page .card.card-payments .card-header.card-header-collapsed .balance {
  color: #000;
}

.old-page .status-default .card-header,
.old-page .status-default .card-body tfoot tr:first-child {
  background-color: #f4f4f4;
}

.old-page .status-default .card-body {
  background-color: #fbfbfb;
}

.old-page .status-default .card-header-collapsed .balance,
.old-page .status-default .card-header-collapsed .chevron,
.old-page .status-default .card-body tfoot tr:last-child {
  background-color: #cecece;
}

.old-page .status-conciliated .card-header,
.old-page .status-conciliated .card-body tfoot tr:first-child {
  background-color: #ddedf9;
}

.old-page .status-conciliated .card-body {
  background-color: #f9fbfc;
}

.old-page .status-conciliated .card-header-collapsed .balance,
.old-page .status-conciliated .card-header-collapsed .chevron,
.old-page .status-conciliated .card-body tfoot tr:last-child {
  background-color: #9ec6e5;
}

.old-page .status-success .card-header,
.old-page .status-success .card-body tfoot tr:first-child {
  background-color: #d6efba;
}

.old-page .status-success .card-body {
  background-color: #eff9e5;
}

.old-page .status-success .card-header-collapsed .balance,
.old-page .status-success .card-header-collapsed .chevron,
.old-page .status-success .card-body tfoot tr:last-child {
  background-color: #8edb71;
}

.old-page .status-error .card-header,
.old-page .status-error .card-body tfoot tr:first-child {
  background-color: #fdd0d0;
}

.old-page .status-error .card-body {
  background-color: #f9e5e5;
}

.old-page .status-error .card-header-collapsed .balance,
.old-page .status-error .card-header-collapsed .chevron,
.old-page .status-error .card-body tfoot tr:last-child {
  background-color: #f45858;
}

.old-page .card .card-header-collapsed .invoices {
  border-left: 1px solid #dfdfdf;
  border-right: 1px solid #dfdfdf;
}

.old-page .card .card-header .chevron {
  width: 30px;
}

.old-page .card .card-header .chevron div {
  background-color: #fff;
  padding: 8px;
  top: -1px;
  right: -1px;
  position: absolute;
  border: 1px solid #dfdfdf;
}

.old-page main .main-panel #nav-tab .nav-item {
  border: 0;
  color: inherit;
}

.old-page main .main-panel #nav-tab .nav-item.active {
  border-bottom: 4px solid #85C7E9;
  font-weight: bold;
}

.old-page .App {
  min-height: calc(100vh - 95px);
}

.old-page .footer {
  width: 100%;
  height: 48px;
  background-color: #616161;
  z-index: 999;
  margin-top: 40px;
  font-size: 12px;
}

.old-page .footer a {
  color: #fff;
}

.old-page .card-header {
  font-size: 13px;
}

.old-page .table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #FAFAFA;
}

.old-page .show-view {
  font-size: 12px;
}

.old-page .show-view label {
  color: #9e9e9e;
  font-weight: normal;
}

.old-page .show-view .form-control {
  background-color: #fafafa;
  /* padding: 10px; */
  height: 32px;
}

.old-page .filters {
  font-size: 12px;
}

.old-page .popover i {
  float: left;
  margin-right: 10px;
  width: 10%;
  /* max-width:400px; */
  text-align: right;
  padding-top: 3px;
}

.old-page .popover .company {
  float: left;
  width: 85%;
  white-space: nowrap;
}

.old-page .popover .list-group-item {
  border: none;
}

.old-page .popover .popover-body {
  min-width: 276px;
}

.old-page .list-group-flush-unstyled .list-group-item {
  border: none;
}

.old-page .list-group-item .actions {
  float: right;
  /* display: inline-block; */
}

.old-page .filters-applied li,
.old-page .filters-applied li>div:first-child {
  border: 1px solid #85C7E9;
}

.old-page .filters-applied li>div:first-child {
  margin: -1px 0 -1px -1px;
}

.old-page .badge-float {
  position: relative;
  padding-right: 10px;
  display: block;
}

.old-page .badge-float .badge {
  position: absolute;
  right: 0;
  top: 0;
}

.old-page .br-1 {
  border-right: 1px solid;
}

.old-page .bt-1 {
  border-top: 1px solid #EBEBEB;
}

.old-page .nav .br-1 {
  border-color: #212121;
}

.old-page .text-muted th {
  font-weight: normal;
}

.old-page .table-align-middle td {
  vertical-align: middle;
}

.old-page .b-b-2 {
  border-bottom: 2px solid #9e9e9e;
}

.old-page .resume .card .card-body {
  text-align: center;
}

.old-page .resume .card strong {
  display: block;
  font-size: 20px;
}

.old-page .chosen-container {
  font-size: 16px;
}

.old-page .chosen-container .chosen-single {
  height: 38px;
  line-height: 38px;
  background: none;
  border-color: #ced4da;
  box-shadow: none;
}

.old-page option:disabled {
  background-color: #E8E8E8;
}

.old-page .grip {
  color: #EBEBEB;
  cursor: grab;
}

.old-page .switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
  margin: 0;
}

.old-page .switch input {
  display: none;
}

.old-page .switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.old-page .switch .slider .label {
  opacity: 0;
  font-weight: normal;
  height: 24px;
  line-height: 26px;
  font-size: 11px;
  text-transform: uppercase;
  float: right;
  padding-right: 4px;
}

.old-page .switch .slider::before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.old-page .switch input:checked+.slider {
  background-color: #4082B6;
}

.old-page .switch input:focus+.slider {
  box-shadow: 0 0 1px #4082B6;
}

.old-page .switch input:checked+.slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

.old-page .switch input+.slider.disabled {
  background-color: #9E9E9E;
  border: 1px solid #9E9E9E;
}

.old-page .switch input+.slider.disabled {
  background-color: #EBEBEB;
  border: 1px solid #E0E0E0;
}

.old-page .switch input+.slider.disabled::before {
  background-color: #9E9E9E;
}

.old-page .switch input:checked+.slider.disabled {
  background-color: #FFF;
}

.old-page .switch input:checked+.slider.disabled::before {
  background-color: #9E9E9E;
}

.old-page .switch .slider.disabled::before {
  bottom: 1px;
}

.old-page .switch .slider.disabled .label {
  opacity: 1;
  color: #9E9E9E;
}

.old-page .switch input:checked+.slider.disabled .label {
  float: left;
  padding-left: 7px;
}

.old-page .switch .slider.round {
  border-radius: 34px;
}

.old-page .switch .slider.round:before {
  border-radius: 50%;
}

.old-page .list-group-item {
  background-color: transparent;
}

.old-page .nav-sidebar {
  border-right: 1px solid #ececec;
  padding-top: 35px;
}

.old-page .nav-sidebar nav li a {
  display: block;
  padding: 0 0 0 10px;
  color: #4082B6;
  border-left: 4px solid transparent;
  line-height: 30px;
}

.old-page .nav-sidebar nav li.active a {
  border-left: 4px solid #4082B6;
}

.old-page .nav-sidebar nav li a.active {
  border-left: 4px solid #4082B6;
}

.old-page .table .input-group-sm .input-group-prepend .input-group-text {
  background-color: #fff;
  border-radius: 0;
}

.old-page .table .input-group.readonly .input-group-text,
.old-page .table .input-group.readonly .money-mask {
  background-color: #e9ecef;
}

.old-page .table .input-group-sm .money-mask {
  border-left: none;
}

.old-page .table .input-group-sm .input-group-append .btn,
.old-page .table .input-group-sm input {
  border-radius: 0;
}

.old-page .va-middle td,
.old-page .va-middle tfoot th {
  vertical-align: middle;
}

.old-page .shadow-hover .card-header {
  padding: 0;
}

.old-page .shadow-hover .card-header>div {
  padding: 0.75rem 1.25rem;
}

.old-page .shadow-hover .card-header .collapsed:hover,
.old-page .shadow-hover tbody tr:hover:not(.dont-shadow),
.old-page div.shadow-hover:hover {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  z-index: 10;
  position: relative;
}

.old-page .hidden {
  visibility: hidden;
}

.old-page .hidden-hover .collapsed:hover .hidden,
.old-page .hidden-hover tr:hover .hidden {
  visibility: visible;
}

.old-page .hidden-hover [data-bs-toggle=collapse][aria-expanded=false]:hover .hidden,
.old-page .hidden-hover [data-bs-toggle=collapse][aria-expanded=true] .hidden {
  visibility: visible;
}

.old-page .hidden-hover .card-header {
  min-height: 47px;
}

.old-page .hidden-hover .card-header .collapsed .fa-chevron-down,
.old-page .hidden-hover .card-header .collapsed .fa-chevron-up,
.old-page .hidden-hover .card-header .collapsed .dropdown {
  display: none;
}

.old-page .hidden-hover .card-header .dropdown {
  display: inline;
  margin-left: 10px;
  top: -2px;
}

.old-page .hidden-hover .card-header .dropdown>a {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
}

.old-page .hidden-hover .card-header:hover .collapsed .fa-chevron-down,
.old-page .hidden-hover .card-header:hover .collapsed .dropdown {
  display: inline-block;
}

.old-page .hidden-hover .card-header>div[class=""] {
  background-color: #f9f9f9;
}

.old-page .hidden-hover .card-header [class=""] .fa-chevron-down,
.old-page .hidden-hover .card-header .has-pointer .fa-chevron-down {
  display: none;
}

.old-page .has-pointer {
  cursor: pointer;
}

.old-page .block-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.old-page .input-lock .input {
  border-left: none;
  text-align: right;
}

.old-page .card-shadow {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
}

.old-page .comments li .btn {
  visibility: hidden;
}

.old-page .comments li:hover .btn {
  visibility: visible;
}

.old-page .chosen-container-single .chosen-single {
  border-radius: 0;
  background-color: #fff;
  font-size: 12px;
  color: #495057;
  border-color: #ccc;
}

.old-page .chosen-container .chosen-results li {
  font-size: 12px;
}

.old-page .chosen-container-single .chosen-single div {
  top: 7px;
}

.old-page .chosen-container-single .chosen-drop {
  margin-top: -3px;
}

.old-page .client-segment {
  cursor: pointer;
}

.old-page .table th.w-xs {
  min-width: 50px;
}

.old-page .table th.w-sm {
  min-width: 100px;
}

.old-page .table th.w-md {
  min-width: 150px;
}

.old-page .table th.w-lg {
  min-width: 220px;
}

.old-page .table th.w-xl {
  min-width: 300px;
}

.old-page .table th.w-xxl {
  min-width: 400px;
}

.old-page .w-xs {
  width: 50px;
}

.old-page .w-sm {
  width: 100px;
}

.old-page .w-md {
  width: 150px;
}

.old-page .w-lg {
  width: 220px;
}

.old-page .w-xl {
  width: 300px;
}

.old-page .w-xxl {
  width: 400px;
}

.old-page table .text-truncate {
  display: block;
}

.old-page .gutters-sm [class*=col-] {
  padding-left: 6px;
  padding-right: 6px;
}

.old-page .form-control {
  padding: 0.375rem 6px;
}

.old-page header {
  height: 95px;
}

.old-page header #company {
  width: 500px;
  margin-right: 20px;
}

.old-page header #company .nav-link {
  padding: 0.75rem 1rem;
  color: #4082B6;
  font-weight: 600;
  margin-top: 0;
}

.old-page header #company .dropdown-item {
  width: 336px;
  padding: 15px 30px;
  border-bottom: 2px solid #fafafa;
}

.old-page header .navbar-brand {
  padding: 0;
}

.old-page header .navbar-brand img {
  max-width: 220px;
  max-height: 40px;
}

.old-page .popover {
  max-width: 100%;
}

.old-page .company-logo-preview {
  background-color: #86c7ea;
}

.old-page td.can-edit a.edit-single-col {
  visibility: hidden;
}

.old-page td.can-edit:hover a.edit-single-col {
  visibility: visible;
}

.old-page .can-edit .chosen-container .chosen-single {
  padding: 0 4px;
  height: 21px;
  line-height: 1.5;
  width: 275px;
}

.old-page .can-edit .chosen-container .input-group-append {
  float: left;
}

.old-page .can-edit .chosen-container-single .chosen-single div {
  top: -2px;
}

.old-page .chosen-container-multi .chosen-choices {
  border-radius: 0;
  font-size: 12px;
  color: #495057;
  background: none;
  background-color: #fff;
  border-color: #ced4da;
  box-shadow: none;
  padding: 6px 8px;
}

.old-page .chosen-container-multi .chosen-choices li.search-choice {
  margin-bottom: 1px;
  background-color: #f2f2f2;
  border-radius: 0;
  border-color: #d2d2d2;
  font-size: 13px;
  background-image: none;
  padding: 6px 20px 6px 10px;
}

.old-page .chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
  top: 8px;
}

.old-page .badge {
  font-weight: 500;
  border: 1px solid #ccc;
}

.old-page .badge-outline-primary {
  color: #0069d9;
  background-color: transparent;
  background-image: none;
  border-color: #0069d9;
  vertical-align: text-top;
}

.old-page .badge-outline-success {
  color: #28a428;
  background-color: transparent;
  background-image: none;
  border-color: #28a428;
  vertical-align: text-top;
}

.old-page thead th {
  font-weight: bold;
}

.old-page .accordion.questions-public .collapse {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.old-page .questions-public .card-header {
  background-color: #f5f6f5;
}

.old-page .questions-public .card-header[aria-expanded=true] .fa-chevron-down {
  display: none;
}

.old-page .questions-public .card-header[aria-expanded=false] .fa-chevron-up {
  display: none;
}

.old-page .accordion-row[aria-expanded=false] .fa-chevron-up {
  display: none;
}

.old-page .accordion-row[aria-expanded=true] .fa-chevron-down {
  display: none;
}

.old-page .tendering-form-section>div:first-child:hover .hidden {
  visibility: visible;
}

.old-page .accordion .tendering-form-section>div.collapse .card {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.old-page input.datepicker,
.old-page input.datepicker-range {
  /* background-image: url('../img/calendar.png'); */
  background-position-x: calc(100% - 8px);
  background-position-y: center;
  background-repeat: no-repeat;
}

.old-page .badge-fluogreen {
  background-color: #43c91a;
  color: white;
}

.old-page .nav-sidebar #form-answers-nav li a {
  position: relative;
  padding: 10px 0 10px 30px;
  line-height: 1.3em;
}

.old-page .nav-sidebar #form-answers-nav li a small {
  position: absolute;
  left: 9px;
  top: 11px;
}

.old-page tr.ui-sortable-helper {
  display: table;
}

.old-page .breadcrumb {
  padding: 0;
  background-color: transparent;
  border-radius: 0;
  font-size: 0.95em;
}

.old-page .breadcrumb.text-muted {
  font-weight: normal;
}

.old-page .breadcrumb-item+.breadcrumb-item::before {
  content: "›";
}

.old-page .hr-dashed {
  border-top: 1px dashed #d2d2d2;
}

.old-page .form-row-bg-default {
  color: black;
  background-color: #ddedf9;
}

.old-page .form-row-bg-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.old-page .form-row-bg-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.old-page .sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.old-page .ellipsis-oneline {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-placeholder {
  color: light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
}

.old-page .multi-line-truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.old-page .tooltip-lg .tooltip-inner {
  max-width: 350px;
}

.old-page input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.old-page .row-bg-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.old-page .row-bg-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.old-page .tooltip {
  pointer-events: none;
}

.old-page .inventory-modal-table-container {
  max-height: 60vh;
}

.old-page .elementcomment-emaillogbody {
  max-height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.old-page ul.no-bullets {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.old-page .login-titulo {
  color: #0076B6;
  font-size: 19px;
  margin-bottom: 15px;
}

.old-page th.customername {
  width: 300px;
}

.old-page th.count {
  width: 100px;
}

.old-page th.amount {
  width: 150px;
}

.old-page th.overdueAmount {
  width: 170px;
}

.old-page th.activityDate {
  width: 400px;
}

.old-page th.calculatedRank {
  width: 50px;
}

.old-page .purchaserequestedit-inventorylist {
  max-height: 44vh;
  overflow-y: scroll;
}

.old-page .table-custom.clientes .badge-priority.priority-1 {
  border: 1px solid #dc3545;
  color: white;
  background-color: #dc3545;
}

.old-page .table-custom.clientes .segmentCriteria p {
  margin-bottom: 0;
}

.old-page .associated-actions {
  position: relative;
  margin-top: 25px;
}

.old-page .associated-actions-title {
  font-size: 14px;
  font-weight: normal;
  color: #4082b6;
  position: absolute;
  background-color: #ddedf9;
  left: 8px;
  margin: 0;
  float: left;
  display: block;
  top: -9px;
}

.old-page .associated-actions-items {
  border: 1px solid rgba(255, 255, 255, 0.6);
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}

.old-page .associated-actions-items p {
  margin: 7px 7px 0 0;
  font-size: 11px;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 3px 8px;
}

.old-page .segmentClients .container {
  max-width: 1400px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.old-page .segmentClients .filters-container {
  display: none;
}

.old-page .modal.show {
  background-color: rgba(0, 0, 0, 0.5);
}

.old-page div.favstar0 {
  background: url(assets/img/ico-star-empty.png) no-repeat;
  width: 16px;
  height: 16px;
  display: inline-block;
}

.old-page div.favstar0:hover {
  background: url(assets/img/ico-star-selected.png);
}

.old-page div.favstar1 {
  background: url(assets/img/ico-star-filled-1.png) no-repeat;
  width: 16px;
  height: 16px;
  display: inline-block;
}

.old-page div.favstar2 {
  background: url(assets/img/ico-star-filled-2.png) no-repeat;
  width: 16px;
  height: 16px;
  display: inline-block;
}

.old-page div.favstar3 {
  background: url(assets/img/ico-star-filled-3.png) no-repeat;
  width: 16px;
  height: 16px;
  display: inline-block;
}

.old-page .toast:not(.show) {
  right: -250px;
}

.old-page .toast {
  transition: all 0.6s ease-out;
  position: relative;
  top: 10px;
  right: 10px;
  max-width: 250px;
}

.old-page .bg-footer {
  background-color: #616161;
}

.old-page .footer-copyright {
  color: #fff;
  padding-right: 1rem;
  font-size: 12px;
}

.old-page .table-custom.clientes .lead {
  font-size: 0.9rem;
}

.old-page .table-custom .card {
  border: 1px solid #ededed;
}

.old-page .table-custom .table thead th {
  color: #959595;
  font-size: 12px;
}

.old-page .text-link {
  color: #2b2b2b;
}

.old-page .table-custom .filters .dropdown-menu .dropdown-item {
  padding: 16px 10px;
  line-height: 18px;
  font-size: 14px;
}

.old-page .table-custom .filters .dropdown-menu .dropdown-item .dropdown-item:focus,
.old-page .table-custom .filters .dropdown-menu .dropdown-item .dropdown-item:hover {
  color: #4082B6;
  background-color: #F4F4F4;
}

.old-page .table-custom .filters .dropdown-menu .dropdown-item.active,
.old-page .table-custom .filters .dropdown-menu .dropdown-item:active {
  color: #fff;
  background-color: #4082B6;
}

.old-page .actions .btn-link[aria-expanded=true] {
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0);
  box-shadow: none;
}

.old-page .actions .dropdown-menu {
  margin-top: -1px;
}

.old-page .row-grid,
.old-page .row-grid-header {
  color: #2b2b2b;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 1fr 0.4fr 65px;
}

.old-page .row-grid a {
  color: #274d7b;
  font-weight: bold;
}

.old-page .row-grid.inactive {
  background-color: rgba(179, 179, 179, 0.25);
  padding: 0.5rem;
  padding-left: 25px;
}

.old-page .row-grid.active {
  background: rgba(52, 191, 96, 0.25);
  padding: 0.5rem;
  padding-left: 25px;
}

.old-page .row-grid.active.disabled {
  background: rgba(247, 147, 30, 0.25);
}

.old-page .text-link[aria-expanded=true] i::before {
  content: "";
  font-family: "Font Awesome 5 Pro";
}

.old-page .text-link.collapsed,
.old-page .text-link[aria-expanded=false] i::before {
  content: "";
}

.old-page .custom-switch.intiza-switch {
  padding-left: 70px;
}

.old-page .custom-switch.intiza-switch.custom-switch-main {
  padding-right: 85px;
}

.old-page .label-on,
.old-page .label-off {
  opacity: 0;
  transition: all 0.4s;
  position: absolute;
  top: 5px;
  left: 0;
  font-size: 11px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}

.old-page .custom-control-input:checked~.custom-control-label .label-on-off .label-on {
  opacity: 1;
}

.old-page .custom-control-input:checked~.custom-control-label .label-on-off .label-off {
  opacity: 0;
}

.old-page .custom-control-input~.custom-control-label .label-on-off .label-off {
  opacity: 1;
}

.old-page .custom-control-input~.custom-control-label .label-on-off .label-on {
  opacity: 0;
}

.old-page .label-on-off {
  position: absolute;
  cursor: pointer;
  white-space: nowrap;
  top: 0;
}

.old-page .custom-control-label::before {
  top: 3px;
  height: 18px;
}

.old-page .custom-control-label::before {
  background-color: #616161;
  border: #616161 solid 1px;
  cursor: pointer;
}

.old-page .custom-control-input:checked~.custom-control-label::before {
  border-color: #34bf60;
  background-color: #34bf60;
  transform: rotate(0);
}

.old-page .custom-switch .custom-control-label::after {
  top: 0;
  left: -60px;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #f7931e;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "";
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f7931e;
  cursor: pointer;
  font-size: 10px;
  transform: rotate(-179deg);
  -webkit-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

.old-page .custom-switch .custom-control-label::before {
  left: -50px;
  width: 40px;
  pointer-events: all;
  border-radius: 2px;
}

.old-page .custom-switch .custom-control-input:checked~.custom-control-label::after {
  -webkit-transform: translateX(29px);
  transform: translateX(29px);
  border: 1px solid #34bf60;
  content: "";
  color: #34bf60;
}

.old-page .custom-control-input:focus~.custom-control-label::before {
  box-shadow: none;
}

.old-page .custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: inherit;
}

.old-page .custom-switch.custom-switch-main .custom-control-label::before {
  top: 1px;
  height: 22px;
  width: 50px;
}

.old-page .custom-switch.custom-switch-main .custom-control-label::after {
  top: -6px;
  left: -60px;
  width: 36px;
  height: 36px;
  font-size: 14px;
}

.old-page .custom-switch-main .label-on,
.old-page .custom-switch-main .label-off {
  top: 4px;
  left: 15px;
  font-size: 13px;
}

.old-page .custom-switch.custom-switch-main .custom-control-input:checked~.custom-control-label::after {
  -webkit-transform: translateX(35px);
  transform: translateX(35px);
}

.old-page .custom-control-input:checked~.custom-control-label .label-on-off .label-on {
  color: #34bf60;
}

.old-page .customSwitchMainMessage {
  display: flex;
}

.old-page .message-on {
  color: #34bf60;
}

.old-page .message-off {
  color: #f7931e;
}

.old-page .row-grid.active.disabled .custom-control-input:checked~.custom-control-label::before {
  border-color: #f7931e;
  background-color: #f7931e;
}

.old-page .row-grid.active.disabled .custom-switch .custom-control-input:checked~.custom-control-label::after {
  border: 1px solid #f7931e;
  color: #f7931e;
}

.old-page .row-grid.active.disabled .custom-control-input:checked~.custom-control-label .label-on-off .label-on {
  color: #f7931e;
}

.old-page .modal-content {
  border: 1px solid #fff;
  border-radius: 0;
}


.modal-logout>p {
  margin: 0 0 16px 16px;
  color: #505050cf;
  font-weight: bold;
  font-size: 12px;
}

.modal-logout>p:hover {
  cursor: pointer;
  color: #505050;
}

.old-page .modal-header {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.old-page .modal-footer {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.old-page .modal-title {
  color: #959595;
  font-size: 18px;
}

.old-page .close {
  color: #646464;
}

.old-page .btn-outline-secondary {
  color: #959595;
  border-color: #ededed;
}

.old-page .btn-outline-danger {
  border-color: #ededed;
}

.old-page .relative {
  position: relative;
}

.old-page .help-tooltips {
  display: flex;
  align-items: center;
}

.old-page .help-tooltips.tooltips-switch {
  position: absolute;
  left: -29px;
  top: 2px;
  display: none;
}

.old-page .help-tooltips i {
  font-size: 20px;
  color: #4082b6;
}

.old-page .row-grid.active.disabled .help-tooltips,
.old-page .row-grid.active.disabled.active .help-tooltips {
  display: block;
}

.old-page .row-grid.inactive.disabled .help-tooltips {
  display: none;
}

.old-page .custom-badges {
  display: flex;
  font-size: 11px;
  font-weight: bold;
  color: #fff;
}

.old-page .badge-active {
  background-color: #34bf60;
  padding: 0 8px;
}

.old-page .badge-inactive {
  background-color: #f7931e;
  padding: 0 8px;
}

.old-page .bg-light-intiza {
  background-color: #ddedf9;
}

.old-page .section-title {
  font-size: 18px;
  font-weight: normal;
  color: #212121;
}

.old-page .section-subtitle {
  font-size: 18px;
  font-weight: normal;
  color: #959595;
}

.old-page .line-right {
  display: flex;
  align-items: center;
}

.old-page .line-right::after {
  content: "";
  border-bottom: 1px solid #ececec;
  width: calc(100% - 10px);
  margin-left: 10px;
}

.old-page .input-group-text {
  padding: 0.375rem 6px;
  font-size: 12px;
  color: #959595;
}

.old-page .affix-top {
  position: fixed;
  top: 50px;
}

.old-page .nav-item-action .list-group-item.active {
  background-color: #4082B6;
  border-color: #4082B6;
}

.old-page .nav-tabs.nav-item-action {
  border: none;
}

.old-page .nav-tabs.nav-item-action .list-group-item {
  border: none;
  font-weight: bold;
  color: #4082B6;
  font-size: 12px;
  padding: 0.35rem 1rem;
  margin-bottom: 5px;
}

.old-page .nav-tabs.nav-item-action .list-group-item.active {
  background: transparent;
  border-left: 3px solid #4082B6;
  margin-left: -3px;
}

.old-page .list-group-item:first-child,
.old-page .list-group-item:last-child {
  border-radius: 0;
}

.old-page .scroll-fix {
  position: relative;
  height: 61vh;
  overflow: auto;
}

.old-page .a-button {
  padding: 0;
  font-family: "Open Sans", sans-serif;
  color: #4082b6;
}

.old-page .form-control:focus {
  border-color: #80bdff;
}

.old-page .segmentos .row-grid,
.old-page .segmentos .row-grid-header {
  grid-template-columns: 1fr 1fr 1fr 1fr 195px 75px;
}

@media (min-width: 980px) {

  .old-page .segmentos .row-grid,
  .old-page .segmentos .row-grid-header {
    grid-template-columns: 435px 0.5fr 0.5fr 1.5fr 195px 75px;
  }
}

.old-page .segment-amount {
  text-align: right;
  padding-right: 24px;
}

.old-page .progress-outdate {
  width: 135px;
  margin-left: auto;
}

.old-page .progress-outdate .progress {
  height: 12px;
}

.old-page .progress-outdate .progress-bar.bg-vencido {
  background-color: #dc3545;
}

.old-page .progress-outdate .progress-bar.bg-novencido {
  background-color: #144485;
}

.old-page .progress-outdate .progress-bar.bg-vencido-client-detail {
  background-color: #ccc;
}

.old-page .badge-priority {
  width: 20px;
  text-align: center;
  line-height: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.old-page .badge-priority.priority-1 {
  border: 1px solid #CC3923;
  background-color: #CC3923;
  color: #fff;
  border-radius: 2px;
}

.old-page .badge-priority.priority-2 {
  border: 1px solid #E86438;
  background-color: #E86438;
  color: #fff;
  border-radius: 2px;
}

.old-page .badge-priority.priority-3 {
  border: 1px solid #FFA807;
  background-color: #FFA807;
  color: #fff;
  border-radius: 2px;
}

.old-page .badge-priority.priority-4 {
  border: 1px solid #E8D527;
  background-color: #E8D527;
  color: #fff;
  border-radius: 2px;
}

.old-page .badge-priority.priority-5 {
  border: 1px solid #A3D519;
  background-color: #A3D519;
  color: #fff;
  border-radius: 2px;
}

.old-page .tooltip-light span {
  display: block;
}

.old-page .tooltip-light span>span {
  display: block;
}

.old-page .tooltip-light span>span:first-child {
  color: #dc3545;
}

.old-page .tooltip-light span>span:last-child {
  color: #144485;
}

.old-page .tooltip-light .tooltip-inner {
  background-color: #fff;
  color: #212529;
  box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  padding: 15px 20px;
  font-weight: bold;
  border: 1px solid #dee2e6;
}

.old-page .tooltip-light.bs-tooltip-top .arrow:before {
  border-top-color: #dee2e6;
  border-width: 8px 10px 0;
}

.old-page .tooltip-light.bs-tooltip-top .arrow:after {
  border-width: 8px 10px 0;
  content: "";
  position: absolute;
  top: -1px;
  border-color: transparent;
  border-style: solid;
  border-top-color: #fff;
}

.old-page .rowHighlight {
  background: #ddedf9;
  padding-top: 1rem;
  margin-bottom: 1rem;
}

.old-page .offset-custom {
  margin-left: 70px;
}

.old-page .w-25px {
  width: 25px;
}

.old-page .w-50px {
  width: 50px;
}

.old-page .w-70px {
  width: 70px;
}

.old-page .w-80px {
  width: 80px;
}

.old-page .w-100px {
  width: 100px;
}

.old-page .w-250px {
  width: 250px;
}

.old-page .clientes .row-grid,
.old-page .clientes .row-grid-header {
  grid-template-columns: 1fr 55px 1fr 135px 75px;
}

@media (min-width: 980px) {

  .old-page .clientes .row-grid,
  .old-page .clientes .row-grid-header {
    grid-template-columns: 215px 1fr 195px 265px 75px;
  }
}

@media (min-width: 1200px) {

  .old-page .clientes .row-grid,
  .old-page .clientes .row-grid-header {
    grid-template-columns: 295px 1fr 195px 395px 75px;
  }
}

.old-page .fixed-list {
  position: relative;
}

.old-page .fixed-list .row-grid-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.old-page .fixed-list .fixed-list-scroll {
  overflow-y: auto;
  height: 46vh;
  margin-top: 52px;
}

.old-page .pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.old-page .clientes .pagination .input-group-text {
  line-height: 29px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0;
}

.old-page .clientes .pagination .form-control {
  height: 31px;
  width: 60px;
}

.old-page .clientes .pagination .page-item .text-muted {
  padding: 0.6rem 0.75rem;
  line-height: 1.25;
  display: block;
}

.old-page .pagination-sm .page-link {
  padding: 0.6rem 0.75rem;
  font-size: 13px;
}

.old-page .page-link {
  color: #4082b6;
}

.old-page nav.pagination.pagination-sm {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.old-page .total-middle-message {
  text-align: center;
}

.old-page .footer-pagination {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  padding-top: 1rem;
  margin-top: 1rem;
}

.old-page .input-group>.input-group-prepend>.input-group-text {
  background-color: #4082B6;
  color: #fff;
  border: 0;
  border-radius: 0px;
  padding: 0 0.75rem;
}

.old-page .pagination .list-unstyled .page-item .page-link {
  border: 0px solid #f8f9fa;
  color: #212529;
  background-color: #f8f9fa;
}

.old-page .pagination .list-unstyled .page-item .page-link:hover {
  color: #212529;
  background-color: #e2e6ea;
}

.old-page .text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
  display: inline-block;
}

.old-page .text-color-vencido {
  color: #dc3545;
}

.old-page .text-color-novencido {
  color: #144485;
}

.old-page .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.old-page .pagination .custom-select {
  height: calc(1.5em + .75rem + 2px);
  font-size: 13px;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-right: none;
  border-left: none;
  border-radius: 0;
}

.old-page .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.old-page .pagination .custom-select:focus,
.old-page .pagination .page-link:focus {
  box-shadow: none;
}

.old-page .pagination .d-flex.list-unstyled {
  display: flex;
  align-items: center;
  margin: 0;
}

.old-page .nav-configuracion .nav-tabs {
  display: flex;
  justify-content: center;
}

.old-page .nav-configuracion .nav-tabs .nav-link.active {
  margin-bottom: -1px;
}

.old-page .nav-configuracion .nav-tabs .nav-link {
  margin-bottom: -1px;
}

.old-page .logo-empresa img {
  max-width: 200px;
}

.old-page .configuracion .row-grid,
.old-page .configuracion .row-grid-header {
  grid-template-columns: 135px minmax(100px, max-content);
}

.old-page .tiposdegestion .row-grid,
.old-page .tiposdegestion .row-grid-header {
  grid-template-columns: 125px 55px 140px 100px 135px 65px 145px 55px 135px 65px;
  white-space: nowrap;
  width: max-content;
}

.old-page .configuracion .row-grid-header {
  font-weight: bold;
}

.old-page .configuracion .monedas .row-grid,
.old-page .configuracion .monedas .row-grid-header {
  grid-template-columns: minmax(100px, max-content) 150px 100px 150px 110px 80px 20px;
}

.old-page .configuracion .plantillas .row-grid,
.old-page .configuracion .plantillas .row-grid-header {
  grid-template-columns: 1fr 250px 65px;
}

.old-page .configuracion label {
  font-weight: normal;
}

.old-page .configuracion .etiquetas .row-grid,
.old-page .configuracion .etiquetas .row-grid-header {
  grid-template-columns: 1fr minmax(70px, max-content);
}

.old-page .configuracion .importaciones .row-grid,
.old-page .configuracion .importaciones .row-grid-header {
  grid-template-columns: 22px 115px 105px 105px 135px 85px 115px 95px 145px 35px 35px;
  white-space: nowrap;
  width: 1078px;
  font-size: 12px;
}

.old-page .configuracion .camposClientes .row-grid,
.old-page .configuracion .camposClientes .row-grid-header {
  grid-template-columns: 155px 100px 85px 111px 20px;
}

.old-page .configuracion .camposFactura .row-grid,
.old-page .configuracion .camposFactura .row-grid-header {
  grid-template-columns: 155px 100px 85px 111px 20px;
}

.old-page .configuracion .camposPago .row-grid,
.old-page .configuracion .camposPago .row-grid-header {
  grid-template-columns: 155px 100px 85px 111px 20px;
}

.old-page .configuracion .listadoEstadoFactura .row-grid,
.old-page .configuracion .listadoEstadoFactura .row-grid-header {
  grid-template-columns: 155px 130px 20px;
}

.old-page .nav-container {
  height: 95px;
  background: linear-gradient(#77b0dc, #86c7ea);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.6);
  font-size: 13px;
  color: #212121;
}

.old-page .primary-section {
  /*background: linear-gradient(#77b0dc, #86c7ea);*/
  font-family: "Open Sans", sans-serif;
  border-bottom: 1px solid #77b0dc;
  margin-bottom: 5px;
}

.old-page .container-primary-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.old-page .section-user-help {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.old-page .enterprise-button {
  background: inherit;
  border: none;
  padding: 0.75rem 1rem;
  font-weight: 600;
  color: #4082B6;
}

.old-page .enterprise-button:hover {
  background: inherit;
  border: none;
  color: #4082B6;
}

.old-page .enterprise-button:focus {
  box-shadow: inherit;
  color: #4082B6;
}

.old-page .enterprise-button:active {
  box-shadow: inherit;
  color: #4082B6;
}

.old-page .btn-light:not(:disabled):not(.disabled):active,
.old-page .show>.btn-light.dropdown-toggle {
  background-color: inherit;
  border-color: inherit;
  color: #4082B6;
}

.old-page .btn-light:not(:disabled):not(.disabled):active:focus,
.old-page .show>.btn-light.dropdown-toggle:focus {
  box-shadow: none;
}

.old-page .show>.btn-light.dropdown-toggle:focus {
  box-shadow: none;
}

.old-page .user-menu-button {
  color: #fff;
  background: inherit;
  border: none;
  border-radius: 0;
  font-size: 13px;
}

.old-page .user-menu-button:hover {
  background: inherit;
  color: rgba(255, 255, 255, 0.75);
  border: none;
}

.old-page .user-menu-button:focus {
  box-shadow: none;
  color: #FFF;
}

.old-page .section-user-help .show>.btn-light.dropdown-toggle {
  color: #fff;
}

.old-page .user-menu-button.btn-light:not(:disabled):not(.disabled):active,
.old-page .user-menu-button.show>.btn-light.dropdown-toggle {
  background-color: inherit;
  border-color: inherit;
  color: #fff;
}

.old-page .primary-section .dropdown-item:active {
  color: black;
  background-color: #f2f4f5;
}

.old-page .secondary-section {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.old-page .item-secondary-section {
  color: #fff;
  padding: 12px 1rem 20px;
}

.old-page .nav-tabs .nav-link.item-secondary-section {
  border: none;
  border-radius: 0px;
}

.old-page .item-secondary-section:hover {
  border: none;
}

.old-page .logo {
  max-width: 220px;
  max-height: 40px;
}

.old-page .nav-tabs .nav-link.item-secondary-section.active {
  color: #212121;
  background-color: #f9fbfc;
}

.old-page a.nav-link.item-secondary-section:hover {
  color: #fff;
}

.old-page .modal-body .lead {
  font-size: 1rem;
}

.old-page .modal-footer .btn-outline-secondary {
  color: #959595;
  border-color: #ededed;
}

.old-page .modal-footer .btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.old-page .messageSwich {
  margin: 0 auto;
  display: grid;
  height: 100vh;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #ededed;
}

.old-page .messageSwichContent {
  color: #6c757d;
}

.old-page .messageSwichAnim {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 2rem 0;
  margin: 0 -5%;
  overflow: hidden;
}

.old-page .messageSwichAnimDot {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #4082B6;
  color: #4082B6;
  animation: AnimDot 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.old-page .messageSwichAnimDot::before,
.old-page .messageSwichAnimDot::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.old-page .messageSwichAnimDot::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #4082B6;
  color: #4082B6;
  animation: AnimDot 1s infinite alternate;
  animation-delay: 0s;
}

.old-page .messageSwichAnimDot::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #4082B6;
  color: #4082B6;
  animation: AnimDot 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes AnimDot {
  0% {
    background-color: #4082B6;
  }

  50%,
  100% {
    background-color: #ededed;
  }
}

.old-page .h-32 {
  height: 32px;
}

.old-page .bb-0 {
  border-bottom: 0px;
}

.old-page .dropdown-toggle-customselect {
  width: 100%;
  text-align: left;
  background-color: white;
  border: 1px solid #cccccc;
}

.old-page .dropdown-toggle-customselect::after {
  float: right;
  margin-top: 8px;
}

.old-page code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.old-page select.with-titles {
  font-size: 14px;
}

.old-page select.with-titles>option:disabled {
  font-weight: bold;
  background-color: transparent;
  color: #000;
}

.old-page .show-child-on-hover:not(:hover) .display-when-hovering-parent {
  display: none;
}

.old-page .show-child-on-hover:not(:hover) .show-when-hovering-parent {
  visibility: hidden;
}

.old-page .pointer {
  cursor: pointer;
}

.old-page .badge.vencido {
  border-radius: 4px;
  line-height: 1rem;
  font-size: 100%;
  display: inline;
}

.old-page .invoice-type-label {
  margin: 0;
  width: 45%;
}

.old-page .invoice-type-input.form-control {
  width: 55%;
}

.old-page .form-group label {
  margin-bottom: 0;
}

.old-page .invoice-detail-buttons {
  display: flex;
  justify-content: flex-end;
}

@media print {
  @page {
    size: auto;
    /* auto is the initial value */
    /* this affects the margin in the printer settings */
    margin: 200mm;
  }

  body.old-page {
    margin: 200mm;
  }

  body.old-page> :not(#section-to-print) {
    display: none;
  }

  .old-page #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
    width: inherit;
    height: inherit;
  }

  .old-page .hideOnPrint {
    visibility: hidden !important;
  }

  .old-page .nav.nav-tabs,
  .old-page .removeOnPrint {
    display: none !important;
  }

  .old-page .tab-content>.tab-pane {
    display: block;
  }

  .old-page #section-to-print>.container>.card {
    border: 0;
  }
}

.old-page #bills .container-fluid {
  max-width: 100%;
}

.old-page #bills label {
  margin-bottom: 0.25rem;
  font-size: 12px;
}

.old-page body#bills {
  overflow-y: hidden;
}

.old-page .left-menu {
  height: calc(100vh - 95px);
  background-color: #fff;
  border-right: 1px solid #dfe3eb;
  overflow-y: auto;
  padding: 1rem;
  overflow-x: hidden;
  position: relative;
  z-index: 0;
  word-break: break-word;
}

@media only screen and (max-width: 1400px) {
  .old-page .left-menu {
    padding: 1rem 0.5rem;
  }
}

.old-page .right-menu {
  height: calc(100vh - 95px);
  background-color: #fff;
  border-left: 1px solid #dfe3eb;
  overflow-y: auto;
  padding: 1rem;
}

.old-page .bills-content {
  height: calc(100vh - 95px);
  overflow-y: auto;
  background-color: #fff;
  padding: 2rem 1.5em;
}

.old-page #bills .nav-fill .nav-item {
  display: flex;
  justify-content: center;
}

.old-page #bills .nav-link:focus {
  outline: 0;
}

.old-page h1.client-name {
  font-size: 20px;
  font-weight: 800;
  color: #2b2b2b;
  padding: 1rem 0;
  margin: 0;
}

.old-page .client-detail {
  padding: 2rem 0;
}

.old-page #bills .card {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.old-page .accordion .card .card-header {
  border: 0px solid rgba(0, 0, 0, 0.125);
  padding: 0;
}

.old-page #bills .accordion .card {
  margin-bottom: 10px;
}

.old-page .bills-content .nav-tabs .nav-item {
  margin-bottom: 0px;
}

.old-page .bills-content .nav-tabs .nav-item:nth-child(6) .nav-link {
  background-color: #fff3cd;
}

.old-page .bills-content .nav-tabs .nav-item:nth-child(6) .nav-link:not(.active) {
  border-bottom: 2px solid #fff3cd;
}

.old-page .bills-content .nav-tabs .nav-link {
  border: 0px solid transparent;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
}

.old-page button.nav-link.active:focus,
.old-page button.nav-link {
  background-color: #fff;
}

.old-page .bills-content button.nav-link {
  background-color: #fff;
}

.old-page .bills-content .nav-link {
  display: block;
  padding: 0.5rem 3rem;
}

.old-page .bills-content .nav-tabs button.nav-link.active {
  border-bottom: 2px solid #4082B6;
}

.old-page .total-receivable-container {
  width: 100%;
  padding: 1rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.old-page .total-receivable {
  font-size: 20px;
  color: #464646;
  font-weight: 700;
}

.old-page .customer-data-container {
  padding: 2rem 0;
}

.old-page .label-total-receivable {
  font-size: 12px;
}

.old-page .overdue {
  color: #ed1c24;
  font-size: 14px;
  font-weight: 700;
}

.old-page .no-overdue {
  color: #0254c7;
  font-size: 14px;
  font-weight: 700;
}

@media only screen and (max-width: 1400px) {

  .old-page .no-overdue span,
  .old-page .overdue span {
    display: block;
  }
}

.old-page .total-receivable-foot-label {
  font-size: 10px;
  color: #000;
  margin: 0;
}

.old-page .total-receivable-foot-text {
  font-size: 10px;
  color: #000;
  margin: 0 0 1rem 0;
}

.old-page .title-underline {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.old-page .circle-button {
  border-radius: 25px;
  border: none;
  background-color: #4082B6;
  height: 35px;
  width: 35px;
}

.old-page .circle-button:focus-visible,
.old-page .circle-button:focus {
  outline: 0px;
}

.old-page .circle-button:active {
  transform: scale(0.98);
}

.old-page .circle-button:hover {
  background-color: #0069d9;
}

.old-page #floating-window>.title-bar,
.old-page div#floating-window.window-container>div.title-bar>span.windowTitle {
  background-color: #4082B6;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-size: 0.9rem;
  color: white;
  text-align: left;
  padding-left: 0.5rem;
}

.old-page div#floating-window.window-container>div.title-bar>span.buttonContainer>span.windowButton {
  color: white;
}

.old-page div#floating-window.window-container>div.title-bar>span.icon {
  width: 0px;
}

.old-page div#floating-window.window-container {
  border-width: 0px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.6);
}

.old-page div#floating-window.window-container>div.title-bar>span.buttonContainer>span.windowButton {
  font-size: 1.3rem;
}

@media (min-width: 480px) {
  .old-page .dropdown:hover .dropdown-menu {
    display: block;
  }
}

.old-page .DraftEditor-root,
.old-page .public-DraftEditor-content {
  height: auto;
}

.old-page .big-editor {
  min-height: 220px;
}

.old-page .floating-window-overlay {
  height: 0px;
  overflow: visible;
  position: fixed;
  pointer-events: none;
  background-color: black;
  opacity: 0;
  left: 0;
  top: 0;
  -webkit-transition: opacity 0.6s ease;
  -o-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
}

.old-page .floating-window-overlay.fadeIn {
  opacity: 0.5;
  pointer-events: auto;
}

.old-page .rdw-colorpicker-modal {
  width: 228px;
  height: 191px;
}

.old-page .rdw-colorpicker-modal-options {
  overflow: auto;
}

.old-page .rdw-link-modal {
  height: 258px;
}

.old-page .collapse {
  display: none;
}

.old-page .collapse.show {
  display: block;
}

.old-page tr.collapse.show {
  display: table-row;
}

.old-page tbody.collapse.show {
  display: table-row-group;
}

.old-page .collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

.old-page .highcharts-credits {
  display: none;
}

.old-page .slick-tab .nav-tabs .nav-item {
  margin-bottom: 0px;
}

.old-page .slick-tab .nav-tabs .nav-link {
  border: 0px solid transparent;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
}

.old-page button.nav-link.active:focus,
.old-page button.nav-link {
  background-color: #fff;
}

.old-page .slick-tab button.nav-link {
  background-color: #fff;
}

.old-page .slick-tab .nav-link {
  display: block;
  padding: 0.5rem 3rem;
}

.old-page .slick-tab .nav-tabs button.nav-link.active {
  border-bottom: 2px solid #4082B6;
}

.old-page .slick-tab .nav-link:focus-visible,
.old-page .slick-tab .nav-link:focus {
  outline: 0px;
}

.old-page .inline-edit {
  height: 29.5px;
  display: inline-block;
  vertical-align: middle;
}

.old-page .table-ellipsis {
  white-space: nowrap;
}

.old-page .table-ellipsis td:not(.text-end) {
  max-width: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.old-page .table-ellipsis td.long-text {
  max-width: 300px;
}

.old-page .logon {
  padding: 120px 0 150px;
  font-family: "Avenir Next";
}

.old-page .logon .box {
  width: 450px;
  padding: 20px;
  margin: auto;
  border-radius: 15px;
  background-color: #fff;
  -webkit-box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.75);
}

.old-page .logon .box h1 {
  color: #1f2b45;
  text-align: center;
  font-size: 33px;
  margin-bottom: 15px;
  font-weight: bold;
}

.old-page .logon .box input[type=email],
.old-page .logon .box input[type=password],
.old-page .logon .box input[type=text] {
  border: none;
  background-size: 20px;
  border-bottom: solid 1px #1f2b45;
  font-size: 15px;
  padding: 6px 6px 14px 6px;
  color: #1f2b45;
  font-weight: bold;
  margin-bottom: 14px;
  width: 100%;
}

.old-page .logon .box input::placeholder {
  color: #1f2b45;
  opacity: 1;
}

.old-page .logon .box .pass {
  text-align: right;
}

.old-page .logon .box .pass a {
  color: rgba(0, 0, 0, 0.4);
  font-weight: bold;
  padding-bottom: 14px;
  font-size: 12px;
}

.old-page .logon .box .pass a:hover {
  color: #1f2b45;
}

.box .back small {
  text-align: left;
  color: #505050cf;
  font-weight: bold;
  padding-bottom: 14px;
  font-size: 12px;
}

.box .back small:hover {
  color: #505050;
  cursor: pointer;
}

.old-page .logon .box .recordar {
  font-weight: bold;
  color: #1f2b45;
  font-size: 14px;
  padding: 9px 0 9px 0;
  vertical-align: middle;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
}

.old-page .logon .box .recordar input[type=checkbox] {
  margin-right: 4px;
  float: left;
  width: 20px;
  height: 20px;
}

.old-page .logon .box .login-btn {
  text-align: center;
}

.old-page .logon .box input[type=submit],
.old-page .logon .box input[type=button],
.old-page #butlogin {
  background-color: #3cc5be;
  color: #fff;
  border-radius: 9px;
  padding: 10px 15px 10px 15px;
  display: block;
  margin: 10px auto auto auto;
  border: none;
  font-size: 15px;
}

.old-page .logon .box input[type=submit]:hover,
.old-page .logon .box input[type=button]:hover,
.old-page #butlogin:hover {
  background-color: #1f2b45;
}

.old-page .logon .box .icono-input {
  position: absolute;
  top: 16px;
  right: 20px;
  cursor: pointer;
}

.old-page .logon .box .texto {
  text-align: center;
  font-size: 12px;
  padding: 9px 0 20px 0;
  line-height: 16px;
}

.old-page .fotohome .botondemo,
.old-page .botondemo {
  padding: 10px 15px 10px 15px;
  border-radius: 9px;
  background-color: #3cc5be;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border: 0px;
}

.old-page .fotohome .botondemo:hover,
.old-page .botondemo:hover {
  background-color: #1f2b45;
  color: #fff;
}

.old-page .fotohome .botonvideo {
  color: #3cc5be;
  font-size: 16px;
  font-weight: 500;
  margin-left: 15px;
}

.old-page .fotohome .botonvideo:hover {
  color: #1f2b45;
}

.old-page #offmenu {
  font-family: "Avenir Next";
  left: 0;
}

.old-page #offmenu>header {
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: none;
  box-shadow: none;
  background-color: #fff;
  z-index: 10;
}

.old-page #offmenu>header ul li {
  display: inline-block;
  margin: 0 18px 0 0;
}

.old-page header ul {
  padding-top: 28px;
}

.old-page header ul li {
  display: inline-block;
  margin: 0 15px 0 0;
}

.old-page header ul li a {
  color: #1f2b45;
  font-size: 15px;
}

.old-page header ul li a:hover {
  color: #3cc5be;
}

.old-page header .col3 {
  padding-top: 20px;
  text-align: right;
}

.old-page header .ingresar {
  padding: 10px 33px 10px 33px;
  color: #fff;
  font-weight: 600;
  border-radius: 6px;
  background-color: #3cc5be;
  font-size: 12px;
}

.old-page header .ingresar:hover {
  background-color: #1f2b45;
}

.old-page header .btn {
  font-weight: 600;
  margin-right: 15px;
  outline: none !important;
}

.old-page header .dropdown-menu {
  min-width: 5rem;
  padding: 0;
  font-weight: 600;
  color: #1f2b45;
}

.old-page header .dropdown-item {
  font-weight: 600;
  color: #1f2b45;
}

.old-page .logomobile {
  display: none;
}

.old-page .headermobile {
  display: none;
}

.old-page .fotohome h1 {
  font-size: 51px;
  line-height: 51px;
}

.old-page .iconomobile {
  display: none;
}

.old-page .login-footer {
  font-family: "Avenir Next";
  color: #fff;
  background-color: #1f2b45;
  padding: 45px 0 60px 0;
}

.old-page .login-footer ul li {
  list-style: none;
}

.old-page .login-footer ul li a {
  color: #fff;
  font-size: 12px;
}

.old-page .login-footer ul li a:hover {
  color: #3cc5be;
}

.old-page #bills .table-no-border>.card,
.old-page .table-no-border>.card {
  border: 0px;
}

.old-page .table-no-border>.card>.card-body {
  padding: 0;
}

.old-page #bills .table-no-border>.card table {
  margin-bottom: 0px;
}

.old-page .configuration-display-item {
  font-size: 14px;
  color: #717171;
  margin-bottom: 10px;
}

.old-page .floating-panel-with-bar {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.old-page .floating-panel-content {
  overflow: auto;
  flex-grow: 1;
}

.old-page .inline-error>p.text-danger {
  align-self: center;
  margin-bottom: 0px;
  padding-left: 0.75rem;
}

.old-page .rank-1 {
  background-color: red;
  color: white;
  padding: 4px 8px;
  border-radius: 3px;
  margin-bottom: 0px;
  font-weight: normal;
}

.old-page .rank-2 {
  background-color: orange;
  color: black;
  padding: 4px 8px;
  border-radius: 3px;
  margin-bottom: 0px;
  font-weight: normal;
}

.old-page .rank-3 {
  background-color: yellow;
  color: black;
  padding: 4px 8px;
  border-radius: 3px;
  margin-bottom: 0px;
  font-weight: normal;
}

.old-page .rank-4 {
  background-color: yellowgreen;
  color: black;
  padding: 4px 8px;
  border-radius: 3px;
  margin-bottom: 0px;
  font-weight: normal;
}

.old-page .rank-5 {
  background-color: green;
  color: white;
  padding: 4px 8px;
  border-radius: 3px;
  margin-bottom: 0px;
  font-weight: normal;
}

.old-page table.sticky-header thead tr {
  background-color: #fff;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 11;
  box-shadow: inset 0 -2px 0 #646464;
}

.old-page .nav-link.item-secondary-section.dropdown-toggle {
  background-color: transparent;
}

.old-page .table-loader-container {
  position: relative;
}

.old-page .table-loader-loading-fade.background-fade {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12;
  -webkit-transition: background-color 0.6s ease;
  -o-transition: background-color 0.6s ease;
  transition: background-color 0.6s ease;
}

.old-page .table-loader-loading-fade i {
  color: #77b0dc00;
}

.old-page .table-loader-loading-fade.background-fade i {
  -webkit-transition: color 0.6s ease;
  -o-transition: color 0.6s ease;
  transition: color 0.6s ease;
  color: #77b0dc;
}



.popover__wrapper .progress {
  position: relative;
  top: 0.38rem;
}

.popover__content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  /* left: -90px;
  bottom: -90px; */
  transform: translate(0, 10px);
  background-color: #fbfbfb;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  width: auto;
  margin-top: 30px;
}

.popover__content:before {
  position: absolute;
  z-index: -1;
  content: "";
  right: calc(50% - 10px);
  top: -8px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #fbfbfb transparent;
  transition-duration: 0.3s;
  transition-property: transform;
}

.popover__wrapper:hover .popover__content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, -20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}

.popover__message {
  text-align: justify;
}

.progress,
.progress-modify {
  --bs-progress-height: 0.85rem;
}

.imgRevert {
  transform: scaleX(-1)
}

.table-container {
  width: 100%;
  overflow: auto;
  max-height: 300px;
}

/* FORMAT INPUT NUMBER */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  text-align: end;
}

.font-weight-bold>td {
  font-weight: bold;
}

/* icon rotate */

.rotate {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.linkAction {
  color: #0076b6;
  display: flex;
  justify-content: flex-end;
  margin: 0 0 5px 0;
  cursor: pointer;
}